import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Backdrop, CircularProgress } from '@mui/material';
import ClientCreateForm from './ClientCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function ClientManagerForm() {
  const { id } = useParams();
  const [clientData, setclientData] = useState([]);
  const [ratesheetData, setratesheetData] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [getstateData, setgetstateData] = useState([]);
  const [paymentData, setpaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [multivalue, setmultivalue] = React.useState([]);
  const [state, setstate] = useState([]);
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm(
    !id
      ? {
          defaultValues: {
            ratesheet: '',
            payment: '',
            clientType: '',
            remark: '',
            active: true,
          },
        }
      : {
          defaultValues: {
            ratesheet: '',
            clientType: '',
            payment: '',
            remark: '',
            active: true,
          },
        }
  );
  const getClientFormById = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENT_BY_ID}/${id}`);
    setstate(data.data[0]);
    setmultivalue(data.data[0]?.client_type);
    setValue('companyName', data?.data[0].company_name);
    setValue('companyEmail', data?.data[0]?.company_email);
    setValue('clientType', data?.data[0].client_type);
    setValue('ratesheet', data?.data[0].rate_sheet);
    setValue('payment', data?.data[0].payment_terms);
    setValue('remark', data?.data[0].remark);
    setValue('active', data?.data[0].active);
    data?.data[0]?.contacts?.map((element, i) => {
      setValue(`contacts.${i}._id`, element._id);
      setValue(`contacts.${i}.firstName`, element.first_name);
      setValue(`contacts.${i}.lastName`, element.last_name);
      setValue(`contacts.${i}.position`, element.position);
      setValue(`contacts.${i}.department`, element.department);
      setValue(`contacts.${i}.location`, element.location);
      setValue(`contacts.${i}.email`, element.email);
      setValue(`contacts.${i}.phone`, element.phone);
      setValue(`contacts.${i}.cell`, element.cell);
      setValue(`contacts.${i}.remark`, element.remark);
    });

    data?.data[0]?.locations?.map((element, i) => {
      setValue(`locations.${i}._id`, element._id);
      setValue(`locations.${i}.locationName`, element.name);
      setValue(`locations.${i}.muncipleAddress`, element.munciple_address);
      setValue(`locations.${i}.stateprovince`, element.state);
      setValue(`locations.${i}.stateprovince_id`, element.state_id);
      setValue(`locations.${i}.city`, element.city);
      setValue(`locations.${i}.city_id`, element.city_id);
      setValue(`locations.${i}.postalCode`, element.postal_code);
    });
    data?.data[0].attachments?.map((element, i) => {
      setValue(`attachments.${i}._id`, element._id);
      setValue(`attachments.${i}.description`, element.description);
      setValue(`attachments.${i}.image`, `${process.env.REACT_APP_ASSET_URL}/client/attachments/${element.file_name}`);
    });
    setLoading(false);
  }, [authAxios, id, setValue]);

  const getclienttypedata = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENTTYPEALL}`);
    const paymentdata = await authAxios.get(`${PRIVATE_ROUTE.GET_PAYMENTTERMALL}`);
    // const ratesheetdata = await authAxios.get(
    //   `${PRIVATE_ROUTE.GET_RATESHEETALL}`
    // );
    const ratesheetdata = [];
    setratesheetData(ratesheetdata?.data?.data);
    setpaymentData(paymentdata.data.data);
    setclientData(data.data);
  }, [authAxios, id]);

  const getstatedata = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_STATEALL}`);
    setstateData(data.data);
  });
  useEffect(() => {
    getstatedata();
    getclienttypedata();
    if (id) {
      getClientFormById();
    }
  }, [getClientFormById, id]);

  const onSubmit = async (data) => {
   
    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_CLIENT}${id}`, data);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/manager/client/list');
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_CLIENT, data);
      if (response.data.statusCode === 200) {
        setLoading(false);
        toast.success(response.data.massage);
        navigate('/manager/client/list');
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    }
    setValue('companyName', '');
    setValue('companyEmail', '');
    setValue('clientType', '');
    setValue('rateSheet', '');
    setValue('paymentTerms', '');
    setValue('remark', '');
    setValue('active', '');
    state?.contacts?.map((element, i) => {
      setValue(`contacts.${i}.firstName`, '');
      setValue(`contacts.${i}.lastName`, '');
      setValue(`contacts.${i}.position`, '');
      setValue(`contacts.${i}.department`, '');
      setValue(`contacts.${i}.location`, '');
      setValue(`contacts.${i}.email`, '');
      setValue(`contacts.${i}.phone`, '');
      setValue(`contacts.${i}.cell`, '');
      setValue(`contacts.${i}.notes`, '');
    });
    state?.locations?.map((element, i) => {
      setValue(`locations.${i}.locationName`, '');
      setValue(`locations.${i}.muncipleAddress`, '');
      setValue(`locations.${i}.stateprovince`, '');
      setValue(`locations.${i}.stateprovince_id`, '');
      setValue(`locations.${i}.city`, '');
      setValue(`locations.${i}.city_id`, '');
      setValue(`locations.${i}.postalCode`, '');
    });
    state?.attachments?.map((element, i) => {
      setValue(`attachments.${i}.description`, '');
      setValue(`attachments.${i}.image`, '');
    });
  };
  return (
    <>
      {id
        ? loading && (
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )
        : ''}
      <ClientCreateForm
        register={register}
        setValue={setValue}
        clientData={clientData}
        stateData={stateData}
        ratesheetData={ratesheetData}
        paymentData={paymentData}
        getValues={getValues}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loader={loading}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
        editdata={state}
        multivalue={multivalue}
        setmultivalue={setmultivalue}
      />
    </>
  );
}

export default ClientManagerForm;
