import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { isLoginStorage } from 'src/utils/isAuthenticated';
// components
import { AuthContext } from 'src/context/AuthContext';
import { authService } from 'src/services/auth.service';
import toast from 'react-hot-toast';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  const { PRIVATE_ROUTE } = API_ROUTES;
  const { authAxios } = useContext(FetchContext);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    
    onSubmit: async (formData) => {
      const response = await authAxios.post(PRIVATE_ROUTE?.FORGOT_PASSWORD, formData);
      if (response.data.statusCode === 200) {
        setLoading(false);
        toast.success(response.data.massage);
        navigate('/login');
      }
      else{
        setLoading(false);
        toast.error(response.data.massage);
        navigate('/login');
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (

    <FormikProvider value={formik}>
      <Typography variant="h4" gutterBottom>
        Forgot Password
      </Typography>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3} pb={4} >
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            variant="filled"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained"  onClick={handleSubmit} loading={isSubmitting}>
          Send Reset Code
        </LoadingButton>
      </Form>
    </FormikProvider>

  );
}
