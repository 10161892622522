import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  TableFooter,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import Icon from 'src/components/color-utils/Icon';
import { AuthContext } from 'src/context/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagerDailyWorkReportlist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const [disablededit, setDisabledEdit] = useState([]);
  const { authAxios } = useContext(FetchContext);
  const [totalEstimatedHours, setTotalEstimatedHours] = useState(0);
  const [totaltask_hour, setTotalTaskHour] = useState(0);
  const { state } = useContext(AuthContext);
  const [sortEnable, setSortEnable] = useState({});
  const [searchEnable, setSearchEnable] = useState();
  const [selectedstartDate, setselectstartDate] = useState(dayjs().subtract(4, 'day').format('YYYY-MM-DD'));
  const [selectendDate, setselectendDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [isValidDate, setIsValidDate] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [enbleAcuracy, setEnableAcuracy] = useState(true);
  const [grandTotaltaskHour, setGrandTotalTaskHour] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getUserdata({
      sortField: sortEnable.sortField,
      sortOrder: sortEnable.sortOrder,
      searchQuery: searchEnable,
      page_no: page,
    });
  }, []);
  const fetchData = async () => {
    if (state.user.role === 'manager') {
      const postData = {
        employee_id: state.user._id,
      };
      try {
        const response = await authAxios.post(PRIVATE_ROUTE.GET_EMPLOYEES_REPORT, postData);
        response?.data?.data?.forEach((item) => {
          // const dwr_labour_cost_item = item?.billing_line_items?.labourCosts[0]?.costitem;
          // const task_labour_cost_item = item?.taskdata?.billing_line_items?.labour_item?.labour_cost_items;
          // const task_estimate_hour = task_labour_cost_item.find(
          //   (cost_item) => cost_item.costItem === dwr_labour_cost_item
          // );
          if (item.status === 1) {
            const estimate_hours = item.billing_line_items?.labourCosts[0]?.estimate_hour;
            setTotalEstimatedHours((prevTotal) => prevTotal + (estimate_hours ? parseFloat(estimate_hours) : 0));
            setTotalTaskHour((prevTotal) => prevTotal + parseFloat(item.task_hour));
          }
        });
        setEnableAcuracy(false);
      } catch (error) {
        console.error('Error fetching employee report:', error);
      }
    }
  };

  function roundToTwo(num) {
    return +(Math.round(num * 100 + 'e+2') + 'e-2');
  }
  const totalAccuracy = roundToTwo(totalEstimatedHours / totaltask_hour);
  const handleEditRedirect = (e, id) => {
    navigate(`/manager/dwr/update/${id}`);
  };
  const handleRowDelete = async (e, id) => {
    if (confirm('Are you sure want to delete?') === true) {
      await authAxios.post(`${PRIVATE_ROUTE?.DELETE_DWR}${id}`);
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
      });
      setOpen(null);
      setSelectedRow(null);
    }
  };

  const handlecreateform = () => {
    navigate('/manager/dwr/create');
  };

  const getUserdata = (query) => {
    setGrandTotalTaskHour(0);
    setLoading(true);
    let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    let start_date = selectedstartDate !== null ? selectedstartDate : '';
    let end_date = selectendDate !== null ? selectendDate : '';
    let search_query = query.searchQuery ? query.searchQuery : '';
    if (typeof query !== 'string') {
      sort_params = `&startDate=${start_date}&endDate=${end_date}&sortField=${query.sortField}&sortOrder=${query.sortOrder}&search=${search_query}`;
    } else {
      sort_params = `&search=${query}&startDate=${start_date}&endDate=${end_date}`;
    }
    let isMounted = true;
    const getUsers = async () => {
      // const { data } = await authAxios.get(
      //   `${PRIVATE_ROUTE?.GET_DWR}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}&user_id=${state.user._id}`
      // );
      if (start_date && end_date) {
        if (enbleAcuracy) {
          fetchData();
        }
        const { data } = await authAxios.get(
          `${PRIVATE_ROUTE?.GET_DWR}?page=${page_cout + 1}&per_page=${rowsPerPage}${sort_params}&user_id=${
            state.user._id
          }`
        );
        if (isMounted) {
          setUsers(
            data?.data?.map((item, index) => {
              const dwr_labour_cost_item = item?.billing_line_items?.labourCosts[0]?.costitem;
              const task_labour_cost_item = item?.taskdata?.billing_line_items?.labour_item?.labour_cost_items;
              const task_estimate_hour = task_labour_cost_item.find(
                (cost_item) => cost_item.costItem === dwr_labour_cost_item
              );
              const manager_name = item.project_managers.map(
                (project_manager) => project_manager.first_name + ' ' + project_manager.last_name
              );
              let stausorignal = 'Pending';
              if (item.status === 1) {
                setGrandTotalTaskHour((prevTotal) => prevTotal + parseFloat(item.task_hour));

                stausorignal = 'Approved';
              } else if (item.status === 2) {
                stausorignal = 'Rejected';
              }
              return {
                _id: item._id,
                dwr_number: (
                  <Link to={`/manager/dwr/view/${item._id}`}>
                    <b>{item?.dwr_number}</b>
                  </Link>
                ),
                client_representative: <b>{item?.client_representative}</b>,
                task_name: item?.taskdata.number_str,
                job_number: item.job_number,
                project_manager: manager_name.join(', '),
                submitted: <Icon color={item?.submit_status === true ? 'green' : 'red'} name="people" size={30} />,
                task_hour: item?.task_hour,
                // task_estimate_hour: stausorignal === 'Approved' ? task_estimate_hour?.estimated_hour : 'NA',
                task_estimate_hour:
                  stausorignal === 'Approved' ? item?.billing_line_items?.labourCosts[0]?.estimate_hour : 'NA',
                end_date: item?.end_date,
                remark: item?.remark,
                status: stausorignal,
                task_date: new Date(item?.task_date).toISOString().split('T')[0],
                cost_item: item?.billing_line_items?.labourCosts[0]?.costitem,
              };
            })
          );
          setCount(data.total);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error('Please select date range', { duration: 5000 });
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  };

  function handleDateSort() {
    if (selectendDate !== null && selectedstartDate !== null) {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
      });
    }
  }
  function handleClearDateSort(selectedstartDate, selectendDate) {
    if (selectedstartDate !== null && selectendDate === null) {
      setIsValid(false);
    } else if (selectedstartDate == null && selectendDate !== null) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    setselectendDate(null);
    setselectstartDate(null);
  }

  useEffect(() => {
    if (isValid) {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
      });
    }
    setIsValid(false);
  }, [isValid]);

  // useEffect(() => {
  //   if (rowsPerPage * page < count) {
  //     getUserdata({
  //       sortField: sortEnable.sortField,
  //       sortOrder: sortEnable.sortOrder,
  //       searchQuery: searchEnable,
  //       page_no: page,
  //     });
  //   } else {
  //     getUserdata({
  //       sortField: sortEnable.sortField,
  //       sortOrder: sortEnable.sortOrder,
  //       searchQuery: searchEnable,
  //     });
  //   }
  // }, [rowsPerPage]);

  const columns = [
    {
      name: '_id',
      label: 'Name',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'dwr_number',
      label: 'DWR Number',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_date',
      label: 'Date',
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_name',
      label: 'Task Number',
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: `job_number`,
      label: 'Job Number',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'project_manager',
      label: 'Project Manager',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'cost_item',
      label: 'Cost Item',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_hour',
      label: 'Dwr Hour',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const color = tableMeta.rowData[9] === 'Approved' ? 'green' : '#212B36';
          const fontWeight = tableMeta.rowData[9] === 'Approved' ? 'bold' : 'normal';
          return <span style={{ color, fontWeight }}>{value}</span>;
        },
      },
    },
    {
      name: 'task_estimate_hour',
      label: 'Task Estimate Hour',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'submitted',
      label: 'Submitted',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    // {
    //   name: 'remark',
    //   label: 'Remark',
    //   options: {
    //     draggable: false,
    //     // sort:true,
    //     filter: true,
    //     display: false,
    //   },
    // },
    {
      name: '',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', gap: '5px' }}>
              <EditIcon
                color={tableMeta.rowData[9] === 'Pending' ? 'inherit' : 'disabled'}
                onClick={
                  tableMeta.rowData[9] === 'Pending' ? (e) => handleEditRedirect(e, tableMeta.rowData[0]) : undefined
                }
              />
              <DeleteIcon
                color={tableMeta.rowData[9] === 'Pending' ? 'inherit' : 'disabled'}
                onClick={
                  tableMeta.rowData[9] === 'Pending' ? (e) => handleRowDelete(e, tableMeta.rowData[0]) : undefined
                }
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    print: true,
    fixedHeader: true,
    filter: false,
    count: count,
    pagination: false,
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((item) => {
        if (typeof item === 'string') {
          // If the item is a string, return it directly
          return item;
        }
        if (typeof item === 'object' && Array.isArray(item.data)) {
          const result = [];

          // Loop through each element in item.data
          item.data.forEach((el) => {
            // If the element is a string, push it directly to the result array
            if (typeof el !== 'object') {
              result.push(el);
            }

            // If it's an object, handle extracting specific values
            if (typeof el === 'object') {
              // Check for the job view (for "001004")
              if (el.props?.to) {
                result.push(el.props.children.props.children);
              }

              // Check for manager and location info
              if (Array.isArray(el.props?.children)) {
                const childrenArray = el.props.children;

                const combinedString = `${childrenArray[1]}, ${childrenArray[5]}, ${childrenArray[9]}, ${childrenArray[13]}`;
                result.push(combinedString);

                // result.push(childrenArray[1], childrenArray[5], childrenArray[9], childrenArray[13]); // Push "manager 232" and "New York"
              }
            }
          });

          return result;
        }

        // In case item is neither a string nor an object in the expected format
        return null;
      });
      const result = formattedData.map((data, index) => ({
        index: index,
        data: data,
      }));
      const footerData = ['', '', '', '', '', 'Total Task Hours', `${grandTotaltaskHour}`, ''];

      return `${buildHead(columns)}${buildBody(result)}\n${footerData.join(',')}\n`;
      // return '\uFEFF' + buildHead(columns) + buildBody(result);
    },
    customToolbar: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px' }}>
          <Typography variant="h6" gutterBottom>
            Total Accuracy : {totalEstimatedHours && totalAccuracy + '%'}
          </Typography>
        </div>
      );
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    // onSearchChange: (searchQuery) => {
    //   if (searchQuery !== null && searchQuery.length > 2) {
    //     getUserdata(searchQuery);
    //     setLoading(true);
    //   }
    //   if (searchQuery === null) {
    //     getUserdata('');
    //   }
    // },

    onSearchChange: (searchQuery) => {
      setSearchEnable(searchQuery);
      if (searchQuery !== null && searchQuery.length > 2) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getUserdata({ sortField, sortOrder, searchQuery }); // Update your API call to include sorting
        } else {
          getUserdata({ searchQuery });
        }
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getUserdata({ sortField, sortOrder }); // Update your API call to include sorting
        } else {
          getUserdata('');
        }
      }
    },

    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    // onTableChange: (action, tableState) => {
    //   if (action === 'changePage') {
    //     setPage(tableState.page);
    //   }
    // },

    // onTableChange: (action, tableState) => {
    //   const { name, direction } = tableState.sortOrder;
    //   const sortField = name; // Column name
    //   const sortOrder = direction; // 'asc' or 'desc'
    //   let searchQuery = searchEnable;
    //   let page_no = tableState.page;
    //   if (action === 'sort') {
    //     setSortEnable(tableState.sortOrder);
    //     if (searchEnable) {
    //       getUserdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
    //     } else {
    //       getUserdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
    //     } // Update your API call to include sorting
    //   }
    //   if (action === 'changePage') {
    //     setPage(tableState.page);

    //     if (searchEnable) {
    //       getUserdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
    //     } else {
    //       getUserdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
    //     }
    //   }
    // },

    onTableChange: (action, tableState) => {
      const { name, direction } = tableState.sortOrder;
      const sortField = name; // Column name
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable({ sortField, sortOrder });
        if (searchEnable) {
          getUserdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getUserdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        } // Update your API call to include sorting
      }
      setPage(tableState.page);
      if (action === 'changePage') {
        if (searchEnable) {
          getUserdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getUserdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
      }
    },
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          <TableFooter>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: '1px solid #808080' }}>
              {opts.columns.map((col, index) => {
                if (col.name === 'dwr_number') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'task_date') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'task_name') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'job_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'project_manager') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'cost_item') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                        textAlign: 'end',
                      }}
                      key={index}
                    >
                      Total:
                    </TableCell>
                  );
                }
                if (col.name === 'task_hour') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      {grandTotaltaskHour}
                    </TableCell>
                  );
                }
                if (col.name === 'task_estimate_hour') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'status') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'submitted') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === '') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableFooter>
        </>
      );
    },
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Daily Work Report
          </Typography>
          {/* <IconButton variant="contained" Icon={<CalendarMonth  />} /> */}
          <Button variant="contained" onClick={handlecreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Daily Work Report
          </Button>
        </Stack>
        <Grid container xs={8} spacing={2} sx={{ py: 2, alignItems: 'center' }}>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                inputFormat="YYYY-MM-DD"
                value={selectedstartDate}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectstartDate(formattedDate);
                }}
                // onChange={(value) => setselectstartDate(value)}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                inputFormat="YYYY-MM-DD"
                value={selectendDate}
                // onChange={(value) => setselectendDate(value)}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectendDate(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
                minDate={selectedstartDate}
                onError={(newError) => setIsValidDate(newError)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleDateSort}
              disabled={selectendDate === null || selectedstartDate === null}
            >
              Submit
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={() => handleClearDateSort(selectedstartDate, selectendDate)}
              disabled={selectendDate === null && selectedstartDate === null}
            >
              Reset
            </Button>
          </Grid> */}
        </Grid>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                )}
                <MUIDataTable options={options} columns={columns} data={users} />
                {!users && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
