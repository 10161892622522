import React, { useCallback, useContext, useEffect } from 'react'
import { Box, Button, Stack, Typography, Container, Card, Tabs, Tab, Grid, Divider, Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper, Backdrop, CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs };
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const { PRIVATE_ROUTE } = API_ROUTES;
const ClientManageView = () => {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const { id } = useParams();
    const [clientData, setclientData] = React.useState();
    const [loading, setLoading] = React.useState(true);


    const clientviewdata = useCallback(async () => {
        const { data } = await authAxios.get(
            `${PRIVATE_ROUTE.GET_CLIENT_BY_ID}/${id}`
        );
        setclientData(data?.data[0]);
        setLoading(false);
    }, [authAxios, id])
    useEffect(() => {
        clientviewdata();
    }, [])
    const handleDelete = async () => {
        if (confirm("Are you sure want to delete?") === true) {
            await authAxios.post(`${PRIVATE_ROUTE?.DELETE_CLIENT}${clientData._id}`);
        navigate('/manager/client/list')
        }
    }
    const handleEdit = () => {
        navigate(`/manager/client/update/${clientData._id}`);
      }
    
    const handleCreate = () => {
        navigate('/job/create');
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Container maxWidth="xl">
                <Typography variant="h4" component="h2">
                    Client View
                </Typography>
                <Box sx={{ marginTop: "20px" }}>
                    <Grid container  >
                        <Grid md={6} xs={12} item>
                            <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid xs={12} md={3} item mt={2} sx={{ maxWidth: 'auto' }}>
                                    <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEdit()}>
                                        Edit
                                    </Button>
                                </Grid>
                                {/* <Grid xs={12} md={4} sx={{ maxWidth: 'auto' }} item>
                                 <a  href={`${process.env.REACT_APP_ASSET_URL}/client/export/client-${clientData?.number_str}.pdf`} target="_blank" rel="noreferrer"  download  style={{textDecoration:"none"}}>   <Button  sx={{ width: 'auto', padding: '7px' }} variant="contained" color="primary" startIcon={<FileDownloadIcon />} >
                                        Export To PDF
                                    </Button> </a>
                                </Grid> */}
                                <Grid xs={12} md={5} item mt={2} sx={{ maxWidth: 'auto' }}>
                                    <Button sx={{ maxWidth: 'auto', marginBlock: 'auto' }} variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => handleDelete()}>
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid md={6} xs={12} item>{''}</Grid>
                    </Grid>
                </Box>
                <Box marginTop={'30px'}>
                    <Typography variant="h6" color="initial">
                        Client No. -{(clientData?.client_number)?.toString().padStart(6, '0')}
                    </Typography>
                    <Button sx={{ width: 'auto', padding: '8px', marginTop: "20px" }} variant="contained" color="primary" onClick={() => handleCreate()} startIcon={<AddIcon />}>
                        Create Job For This Client
                    </Button>
                    <Typography mt={'30px'} variant="h6" color="initial">
                        Client Details
                    </Typography>
                    <Card style={{ marginTop: '30px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Company" {...a11yProps(0)} />
                                    <Tab label="Contacts" {...a11yProps(1)} />
                                    <Tab label="Locations" {...a11yProps(2)} />
                                    {/* <Tab label="Jobs" {...a11yProps(3)} /> */}
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Company
                                </Typography>
                                <Grid sx={{ marginLeft: 6 }} container>
                                    <Grid sx={{ marginTop: "10px" }} md={2} xs={5} item>
                                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                            Company Name:
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={10} xs={7} item>
                                        <Typography variant="body1" color="initial">
                                            {clientData?.company_name}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={2} xs={5} item>
                                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                            Company Email:
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={10} xs={7} item>
                                        <Typography variant="body1" color="initial">
                                            {clientData?.company_email}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={2} xs={5} item>
                                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                            payment Terms:
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={10} xs={7} item>
                                        <Typography variant="body1" color="initial">
                                            <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                                                {clientData?.payment_terms}
                                            </a>
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={2} xs={5} item>
                                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                            Client Type(s):
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "10px" }} md={10} xs={7} item>
                                        <Typography variant="body1" color="initial">
                                            {clientData?.client_type.map((item) => item).join(", ")}
                                        </Typography>
                                    </Grid>
                                    {/* <Grid sx={{ marginTop: "10px" }} md={2} xs={5} item>
                                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                            Rate Sheet:
                                        </Typography>
                                    </Grid> */}
                                    <Grid sx={{ marginTop: "10px" }} md={10} xs={7} item>
                                        <Typography variant="body1" color="initial">
                                            <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                                                <Link to={`/rate-sheet/view/${clientData?.rate_sheet_id}`}> {clientData?.rate_sheet} </Link>
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>

                                    <Grid sx={{ marginTop: "20px" }} md={2} xs={3} item>
                                        <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                            Notes:
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "20px" }} md={10} xs={9} item>
                                        <Typography mb={'5px'} variant="body1" color="initial">
                                            {clientData?.remark}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "20px" }} xs={12} item>
                                        <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                            Attachments:
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ marginTop: "20px", display: "flex", flexWrap: 'wrap' }} xs={10} item>
                                        {clientData?.attachments?.map((item, index) => {
                                            return (
                                                <>
                                                    <Grid sx={{ display: "block", width: 'auto' }}>
                                                        <Grid >
                                                            <img key={index} src={`${process.env.REACT_APP_ASSET_URL}/client/attachments/${item?.file_name}`}
                                                                style={{ margin: "10px", height: "100px", width: "100px" }} />
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ maxWidth: 'auto' }} >
                                                            <Button sx={{ margin: "10px", padding: '5px', minWidth: '92px' }} variant="contained" color="primary">
                                                                <a href={`${process.env.REACT_APP_ASSET_URL}/client/attachments/${item?.file_name}`} download rel="noreferrer" target={"_blank"} style={{ textDecoration: "none", color: 'white' }} label="download" >
                                                                    {'View Image'}
                                                                </a>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {clientData?.attachments.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Contacts
                                </Typography>
                                {clientData?.contacts.map((item) => (
                                    <>
                                        <Grid sx={{ marginLeft: 6 }} container>
                                            <Grid sx={{ marginTop: "10px" }} md={2} sm={3} xs={4} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Full Name:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} sm={9} xs={8} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.first_name + "  " + item.last_name}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Position:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                                                        {item.position}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Depatrment:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.department}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Location:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    <a style={{ color: "black", textDecoration: 'none' }} href={'https://elevatelandsurveying.com/'}>
                                                        {item.location}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Email:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    <a style={{ color: "black", textDecoration: 'none' }} href={`mailto:${item.email}`}>
                                                        {item.email}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Phone:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    <a style={{ color: "black", textDecoration: 'none' }} href={'tel:+14578545214'}>
                                                        {item.phone}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Cell:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    <a style={{ color: "black", textDecoration: 'none' }} href={''}>
                                                        {item.cell}
                                                    </a>
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={2} xs={4} sm={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Notes:
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={10} xs={8} sm={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.remark}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {clientData?.contacts.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                    </>
                                ))}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Locations
                                </Typography>
                                {
                                    clientData?.locations.map((item) => (
                                        <>
                                            <Grid sx={{ marginLeft: 6 }} container>
                                                <Grid sx={{ marginTop: "10px" }} md={2} xs={5} sm={4} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        Munciple Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={10} xs={7} sm={8} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.munciple_address}
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={2} xs={5} sm={4} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        State / Province:
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={10} xs={7} sm={8} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.state}
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={2} xs={5} sm={4} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        City:
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={10} xs={7} sm={8} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.city}
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={2} xs={5} sm={4} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        Postal Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: "10px" }} md={10} xs={7}
                                                    sm={8} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.postal_code}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {clientData.locations.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                        </>
                                    ))
                                }
                            </TabPanel>
                            {/* <TabPanel value={value} index={3}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Jobs
                                </Typography>
                                <TableContainer sx={{ border: "1px solid #cccccc" }} component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell># Job</TableCell>
                                                <TableCell align='center'>Status</TableCell>
                                                <TableCell align='center'>Order Date</TableCell>
                                                <TableCell align='center'>Due Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {clientData?.jobs?.map((item) => (
                                                <TableRow
                                                    key={item.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Link to={`/job/view/${item._id}`}>{item.name}</Link>
                                                    </TableCell>
                                                    <TableCell align='center'>{item.status}</TableCell>
                                                    <TableCell align='center'>{item.order_date}</TableCell>
                                                    <TableCell align='center'>{item.due_date}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </TabPanel> */}
                        </Box>
                    </Card>
                    {loading && (
                        <div>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}

                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    )}

                    <div style={{ marginTop: "30px", display: 'flex' }}>
                        {clientData?.active === true ? <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />}
                        <Typography mb={'5px'} variant="h6" color="initial">
                            Active
                        </Typography>
                    </div>


                </Box>
            </Container>
        </>
    )
}

export default ClientManageView