export const API_ROUTES = {
  BASE_URL: 'api/',
  PUBLIC_ROUTE: {
    LOGIN: '',
    REGISTER: '',
  },
  PRIVATE_ROUTE: {
    CREATE_CLIENT: 'client/create',
    GET_CLIENT: 'client',
    DELETE_CLIENT: 'client/delete/',
    GET_CLIENT_BY_ID: 'client/one',
    EDIT_CLIENT: 'client/update/',
    GET_CLIENTALL: 'client/all',

    CREATE_JOB: 'job/create',
    GET_JOB: 'job',
    GET_MANAGER_JOB: 'job/manager/',
    DELETE_JOB: 'job/delete/',
    GET_JOB_BY_ID: 'job/one',
    EDIT_JOB: 'job/update/',
    GET_JOBALL: 'job/all',
    GET_QUOTEALL: 'quote/all',

    CREATE_TASK: 'task/create',
    GET_TASK: 'task',
    GET_TASKALL: 'task/all',
    GET_TASK_INITAL_DATA: 'task/inital',
    GET_MANAGER_TASK: 'task/manager/',
    DELETE_TASK: 'task/delete/',
    GET_TASK_BY_ID: 'task/one',
    EDIT_TASK: 'task/update/',
    EDIT_TASK_STATUS: 'task/update_status/',
    GET_TASK_DWR_SEARCH: 'task/dwr/search',
    GET_TASK_TOTAL_ESTIMATED_HOURS:'task/totalEstimatedBillableCost/:status',
    GET_JOB_SEARCH: 'job/search',

    UPDATE_TASK_ESTIMATED_HOURS: 'task/updateTaskEstimatedHours/',

    GET_INVOICE: 'invoice',
    GET_TASK_DATA: 'invoice/client',
    CREATE_INVOICE: 'invoice/generate_invoice',
    GENERATE_TOKEN: 'invoice/generate_token',

    CREATE_USER: 'user/create',
    GET_USER: 'user',
    GET_MANAGER: 'user/manager',
    GET_STAFF: 'user/employee',
    GET_ADMIN: 'user/admin',
    DELETE_USER: 'user/delete/',
    GET_USER_BY_ID: 'user/one/',
    EDIT_USER: 'user/update/',

    CHANGE_PASSOWRD: 'user/change-password/',
    EDIT_PROFILE: 'user/edit/',
    GET_USERALL: 'user/',
    FORGOT_PASSWORD: 'forgot-password',
    CONFIRM_PASSWORD: 'change-password-forgot/',
    INITIAL_CHANGE_PASSWORD: 'user/initial-change-password/',

    CREATE_STATE: 'state/create',
    GET_STATE: 'state',
    DELETE_STATE: 'state/delete/',
    GET_STATE_BY_ID: 'state/one',
    EDIT_STATE: 'state/update/',
    GET_STATEALL: 'state/all',

    CREATE_CITY: 'city/create',
    GET_CITY: 'city',
    DELETE_CITY: 'city/delete/',
    GET_CITY_BY_ID: 'city/one',
    EDIT_CITY: 'city/update/',
    GET_CITYALL: 'city/all',

    CREATE_OFFICE: 'office/create',
    GET_OFFICEALL: 'office/all',
    GET_OFFICE: 'office',
    DELETE_OFFICE: 'office/delete/',
    GET_OFFICE_BY_ID: 'office/one',
    EDIT_OFFICE: 'office/update/',

    CREATE_TAX: 'tax/create',
    GET_TAX: 'tax',
    DELETE_TAX: 'tax/delete/',
    GET_TAX_BY_ID: 'tax/one',
    EDIT_TAX: 'tax/update/',
    GET_TAXALL: 'tax/all',

    CREATE_CLIENTTYPE: 'clienttype/create',
    GET_CLIENTTYPE: 'clienttype',
    DELETE_CLIENTTYPE: 'clienttype/delete/',
    GET_CLIENTTYPE_BY_ID: 'clienttype/one',
    EDIT_CLIENTTYPE: 'clienttype/update/',
    GET_CLIENTTYPEALL: 'clienttype/all',

    CREATE_PAYMENTTERM: 'paymentterm/create',
    GET_PAYMENTTERM: 'paymentterm',
    DELETE_PAYMENTTERM: 'paymentterm/delete/',
    GET_PAYMENTTERM_BY_ID: 'paymentterm/one',
    EDIT_PAYMENTTERM: 'paymentterm/update/',
    GET_PAYMENTTERMALL: 'paymentterm/all',

    CREATE_RATESHEET: 'ratesheet/create',
    GET_RATESHEET: 'ratesheet',
    DELETE_RATESHEET: 'ratesheet/delete/',
    GET_RATESHEET_BY_ID: 'ratesheet/one',
    EDIT_RATESHEET: 'ratesheet/update/',
    GET_RATESHEETALL: 'ratesheet/all',

    CREATE_QUOTE: 'quote/create',
    GET_QUOTE: 'quote',
    DELETE_QUOTE: 'quote/delete/',
    GET_QUOTE_BY_ID: 'quote/one',
    EDIT_QUOTE: 'quote/update/',
    GET_QUOTEALL: 'quote/all',

    CREATE_DWR: 'dwr/create',
    GET_DWR: 'dwr',
    DELETE_DWR: 'dwr/delete/',
    GET_DWR_BY_ID: 'dwr/one',
    EDIT_DWR: 'dwr/update/',
    EDIT_DWR_STATUS: 'dwr/update_status/',
    GET_DWRALL: 'dwr/all',
    GET_DWR_HOURS_BY_TASK_ID: 'dwr/task/',
    CREATE_DWR_REPORT: 'dwrreports/create',
    GET_EMPLOYEES_REPORT: 'dwrreports/get',

    CREATE_COSTITEAM: 'costiteam/create',
    GET_COSTITEAM: 'costiteam',
    DELETE_COSTITEAM: 'costiteam/delete/',
    GET_COSTITEAM_BY_ID: 'costiteam/one',
    EDIT_COSTITEAM: 'costiteam/update/',
    GET_COSTITEAMALL: 'costiteam/all',

    CREATE_VEHICLE: 'vehicle/create',
    GET_VEHICLE: 'vehicle',
    DELETE_VEHICLE: 'vehicle/delete/',
    GET_VEHICLE_BY_ID: 'vehicle/one',
    EDIT_VEHICLE: 'vehicle/update/',

    CREATE_JOBSCOPE: 'jobscope/create',
    GET_JOBSCOPEALL: 'jobscope/all',
    GET_JOBSCOPE: 'jobscope',
    DELETE_JOBSCOPE: 'jobscope/delete/',
    GET_JOBSCOPE_BY_ID: 'jobscope/one',
    EDIT_JOBSCOPE: 'jobscope/update/',

    CREATE_JOBSTATUS: 'jobstatus/create',
    GET_JOBSTATUSALL: 'jobstatus/all',
    GET_JOBSTATUS: 'jobstatus',
    DELETE_JOBSTATUS: 'jobstatus/delete/',
    GET_JOBSTATUS_BY_ID: 'jobstatus/one',
    EDIT_JOBSTATUS: 'jobstatus/update/',
    GET_JOBSTATUSALL: 'jobstatus/all',

    CREATE_JOBCATEGORY: 'jobcategory/create',
    GET_JOBCATEGORYALL: 'jobcategory/all',
    GET_JOBCATEGORY: 'jobcategory',
    DELETE_JOBCATEGORY: 'jobcategory/delete/',
    GET_JOBCATEGORY_BY_ID: 'jobcategory/one',
    EDIT_JOBCATEGORY: 'jobcategory/update/',

    CREATE_FEE: 'fee/create',
    GET_FEE: 'fee',
    DELETE_FEE: 'fee/delete/',
    GET_FEE_BY_ID: 'fee/one',
    EDIT_FEE: 'fee/update/',
    GET_FEEALL: 'fee/all',

    GET_CATEGORY: 'category',

    GET_IDENTITY_COUNTER: 'identitycounter',
  },
};
