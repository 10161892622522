import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate,Link, useParams } from 'react-router-dom';
// @mui
import {  Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { isLoginStorage } from 'src/utils/isAuthenticated';
// components
import { AuthContext } from 'src/context/AuthContext';
import { authService } from 'src/services/auth.service';
import toast from 'react-hot-toast';
import { LOGIN } from 'src/context/constatnts';
import Iconify from '../../../components/iconify';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';

// ----------------------------------------------------------------------

export default function ConfirmPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const { authAxios } = useContext(FetchContext);
  const { PRIVATE_ROUTE } = API_ROUTES;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const LoginSchema = Yup.object().shape({
    new_password: Yup.string().required('New Password is required').min(6, "Password should be at least 6 characters."),
    password: Yup.string().required('Password is required').min(6, "Password should be at least 6 characters.")
  });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      password: ''
    },
    validationSchema: LoginSchema,

    onSubmit: async (formData) => {
      if(formData.new_password !== formData.password) {
        toast.error('Your New Password And Confirm Password did Not Match');  
    }
    else if(formData){
      const response = await authAxios.post(`${PRIVATE_ROUTE?.CONFIRM_PASSWORD}${token}`, formData);
      console.log(response);
    
      if (response.data.statusCode === 200) {
        setLoading(false);
        toast.success(response.data.massage);
        navigate('/login');
      }
      else{
        setLoading(false);
        toast.error(response.data.massage);
        navigate('/login');
      }
    }
    }

  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (
   
       <FormikProvider value={formik}>
        <Typography variant="h4" gutterBottom>
           Reset Password
          </Typography>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3} >
      <TextField
            fullWidth
            autoComplete="new password"
            variant="filled"
            label="New Password"
            type={newPassword ? 'text' : 'password'}
            {...getFieldProps('new_password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setNewPassword(!newPassword)} edge="end">
                    <Iconify icon={newPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.new_password && errors.new_password)}
            helperText={touched.new_password && errors.new_password}
          />

        <TextField
          name="password"
          autoComplete="current-password"
          label="Confirm Password"
          variant="filled"
          type={showPassword ? 'text' : 'password'}
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
       
        {/* <Link to={`/resetpassword`} variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained"   loading={isSubmitting}>
       Submit
      </LoadingButton>
      </Form>
    </FormikProvider>
   
  );
}
