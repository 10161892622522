import { LoadingButton } from '@mui/lab';
import { Button, FormLabel, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { FetchContext } from 'src/context/FetchContext';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { API_ROUTES } from 'src/services/constant';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { AuthContext } from 'src/context/AuthContext';

export default function SettingPage() {
  const [items, setItems] = React.useState('');
  const [newPassword, setnewPassword] = React.useState('');
  const [confirmPassword, setconfirmPassword] = React.useState('');
  const [showcurrPassword, setShowCurrPassword] = useState(false);
  const [shownewPassword, setShowNewPassword] = useState(false);
  const [showconPassword, setShowConPassword] = useState(false);

  const { authAxios } = useContext(FetchContext);
  const { state, dispatch } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    defaultValues: {
      curr_password: '',
      password: '',
      confirm_password: '',
    },
  });
  const { PRIVATE_ROUTE } = API_ROUTES;
  const changePassword = () => {
    const localStorageData = JSON.parse(localStorage.getItem('LAND-FRONTED'));
    setItems(localStorageData?.user._id);
  };
  useEffect(() => {
    changePassword();
  }, [newPassword, confirmPassword]);

  const onSubmit = async (data) => {
    if(data.password !== data.confirm_password) {
      toast.error('Your New Password And Confirm Password did Not Match');
    }
    else if(data) {
      const  {data :{massage,statusCode,token,user}}  = await authAxios.post(`${PRIVATE_ROUTE?.CHANGE_PASSOWRD}${items}`, data);
      if (statusCode !== 200) {
        toast.error(massage)
      }
      else {
        dispatch({
          type: 'LOGIN',
          payload: {
            token,
            massage,
            statusCode,
            user
          }
        });
        toast.success('Change Settings successfully');
        setValue('curr_password', '');
        setValue('password', '');
        setValue('confirm_password', '');
      }
    }
   
    }

  const handleClickShowPassword = () => setShowCurrPassword(!showcurrPassword);
  const handleClicknewPassword = () => setShowNewPassword(!shownewPassword);
  const handleClickconfirmPassword = () => setShowConPassword(!showconPassword);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        <Typography variant="h4" gutterBottom>
          Edit Setting
        </Typography>
        <Grid>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} item>
              <Grid>
                <FormLabel>
                  <>
                    Current Password<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
              </Grid>
              <TextField
                fullWidth
                {...register('curr_password', {
                  required: '**Field is required and needs to be a Valid Password',
                })}
                type={showcurrPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showcurrPassword ? <Visibility className='password_visibale_icon' /> : <VisibilityOff className='password_visibale_icon' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors.curr_password)}
                helperText={errors.curr_password?.message}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              {''}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid>
                <FormLabel>
                  <>
                    New Password<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
              </Grid>
              <TextField
                fullWidth
                onChange={(e) => setnewPassword(e.target.value)}
                {...register('password', {
                  required: '**Field is required and needs to be a Valid Password', minLength: {
                    value: 6,
                    message: 'Password should be at least 6 characters.'
                  }
                })}
                type={shownewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClicknewPassword}
                      >
                        {shownewPassword ? <Visibility className='password_visibale_icon' /> : <VisibilityOff className='password_visibale_icon' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              {''}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid>
                <FormLabel>
                  <>
                    Confirm Password<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
              </Grid>
              <TextField
                fullWidth
                onChange={(e, val) => setconfirmPassword(e.target.value)}
                {...register('confirm_password', {
                  required: '**Field is required and needs to be a Valid Password', minLength: {
                    value: 6,
                    message: 'Password should be at least 6 characters.'
                  }
                })}
                type={showconPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickconfirmPassword}
                      >
                        {showconPassword ? <Visibility className='password_visibale_icon' /> : <VisibilityOff className='password_visibale_icon' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(errors.confirm_password)}
                helperText={errors.confirm_password?.message}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Stack>
    </form>
  );
}
