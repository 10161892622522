import React, { useContext, useEffect, useRef } from 'react';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Card,
  FormLabel,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  createTheme,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import ClientaddressDropdown from 'src/components/customecomponent/ClientaddressDropdown';

export default function JobCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
  taxData,
  feeData,
  jobstatusData,
  editdata,
  initialData,
  stateData,
}) {
  const { PRIVATE_ROUTE } = API_ROUTES;
  const navigate = useNavigate();
  const { authAxios } = useContext(FetchContext);
  const [condition, setCondition] = React.useState(params ? getValues('selectClient') : '');
  const [dueDate, setDueDate] = useState();
  const [orderDate, setOrderDate] = useState();
  const [selected, setSelected] = React.useState('Detailed');
  const [taxes, settaxes] = React.useState('TicketInvoice');
  const [value, setValueTab] = React.useState(0);
  const [checkbox, setcheckbox] = useState(true);
  const [jobData, setjobData] = React.useState([]);
  const [categoryvalue, setcategoryvalue] = React.useState('');
  const [clientlocation, setClientLocation] = useState([]);
  let [textAreaCount, settextAreaCount] = React.useState(0);
  const [personName, setPersonName] = React.useState('');
  const [identifyCount, setIdentifyCount] = React.useState(0);

  async function quoteclientdata(clientid) {
    const quotdata = await getclientlocationdata(clientid);
    if (!params) {
      setValue('clientLocation', quotdata[0]?.name);
      setValue('clientLocation_id', quotdata[0]?._id);
    }
  }
  async function getclientlocationdata(client_id) {
    const clientLocationResponse = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENT_BY_ID}/${client_id}`);
    const clientLocationData = clientLocationResponse?.data?.data[0]?.locations;
    setClientLocation(clientLocationData);
    return clientLocationData;
  }
  async function identitycounter() {
    const identitycount = await authAxios.get(`${PRIVATE_ROUTE.GET_IDENTITY_COUNTER}/job`);
    setIdentifyCount(identitycount.data.count);
  }
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
    },
  });
  let clientState = 'selectClient';
  const clientStateData = clientState.split('.');
  let checked = 'checked';
  let errorMassage = false;
  if (!checked) {
    if (
      errors !== null &&
      errors[`${clientStateData[0]}`] &&
      errors[`${clientStateData[0]}`][`${clientStateData[1]}`] &&
      errors[`${clientStateData[0]}`][`${clientStateData[1]}`][`${clientStateData[2]}`]
    ) {
      errorMassage = true;
    }
  } else if (errors !== null && errors[`${clientStateData[0]}`]) {
    errorMassage = true;
  }
  const {
    fields: taxItem,
    remove: removeTax,
    insert: insertTax,
  } = useFieldArray({
    control,
    name: 'taxItem',
  });
  const {
    fields: feesItem,
    remove: removeFees,
    insert: insertFees,
  } = useFieldArray({
    control,
    name: 'feesItem',
  });
  const taxdata = taxData?.map((item) => {
    return { label: item.name, id: item._id };
  });
  const feedata = feeData?.map((item) => {
    return { label: item.name, id: item._id };
  });
  const editorderdate = getValues('orderDate');
  const editduedate = getValues('dueDate');
  const editinvoiceline = getValues('invoiceLineItemType');
  const edittaxesname = getValues('taxesName');
  const editcheckbox = getValues('active');
  useEffect(() => {
    insertTax(taxItem.length, {
      tax_percentage: '5',
      taxesName: 'GST',
    });
    if (isEdit === 'false') {
      identitycounter();
    }
  }, []);
  useEffect(() => {
    setIdentifyCount(editdata?.job_number);
    if (params) {
      let editOrderDate = editorderdate;
      let editDueDate = editduedate;
      if (!(typeof editOrderDate === "string")) {
        editOrderDate = editOrderDate.toISOString().split("T")[0];
      }
      if (!(typeof editDueDate === "string")) {
        editDueDate = editDueDate.toISOString().split("T")[0];
      }

      if (isNaN(editOrderDate) && isNaN(editDueDate)) {
        editOrderDate = editOrderDate.split("T")[0];
        editDueDate = editDueDate.split("T")[0];
      }
      setOrderDate(editOrderDate || null);
      setDueDate(editDueDate || null);
      setSelected(editinvoiceline || null);
      settaxes(edittaxesname || null);
      setcheckbox(editcheckbox || null);
    }
  }, [editorderdate, editduedate, editinvoiceline, edittaxesname, editcheckbox]);

  React.useEffect(() => {
    if (params && editdata?.tax?.length !== 0) {
      editdata?.tax?.map((element, i) => {
        handleTaxesButton();
        handleTaxesDelete(taxItem?.length);
      });
    }
    if (params && editdata?.fee?.length !== 0) {
      editdata?.fee?.map((element, i) => {
        handleFeesButton();
        handleFeesDelete(feesItem?.length);
      });
    }
    if (params && editdata?.client_id?._id) {
      const clientJobData = initialData?.jobinitial
        ?.filter((item) => item?.client_id === editdata?.client_id?._id)
        ?.map((item, index) => {
          return item;
        });
      getclientlocationdata(editdata?.client_id?._id);

      setjobData(clientJobData);
    }
    if (params && editdata?.locations?.length !== 0) {
      editdata?.locations?.map((element, i) => {
        // handleLocationform();
        // handleLocationDelete(locations?.length);
      });
    }
  }, [editdata]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleFeesDelete = (index) => {
    removeFees(index, 1);
  };
  const handleTaxesDelete = (index) => {
    removeTax(index, 1);
  };
  const handleTaxesButton = () => {
    insertTax(taxItem.length, {
      tax_percentage: '',
      taxesName: '',
    });
  };
  const handleFeesButton = () => {
    insertFees(feesItem.length, {
      fees_percentage: '',
      FeesName: '',
    });
  };
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  // *************************************************************CLIENT ONCHANGE*********************************************************************************************
  const handleChangeField = async (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
    setValue('selectClient', value?.split(',')[0]);
    setValue('selectClient_id', value?.split(',')[1].trim());
    setPersonName(getValues('selectClient'));
    getclientlocationdata(getValues('selectClient_id'));

    const clientJobData = initialData?.jobinitial
      ?.filter((item) => item?.client_id === getValues('selectClient_id'))
      ?.map((item, index) => {
        return item;
      });

    setjobData(clientJobData);
    setCondition(value?.split(',')[0]);
  };
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {params ? (
          <>
            {' '}
            <Typography variant="h4" gutterBottom component="h2">
              Edit job : {identifyCount}
            </Typography>{' '}
          </>
        ) : (
          <>
            {' '}
            <Typography variant="h4" gutterBottom component="h2">
              Create job : {identifyCount}
            </Typography>{' '}
          </>
        )}
        <Grid>
          <>
            <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              {/* <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Project<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name="client_project"
                  {...register('client_project', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.client_project)}
                  helperText={errors.client_project?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid> */}

              {/* <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Quote<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name="PO"
                  min={0}
                  {...register('PO', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.PO)}
                  helperText={errors.PO?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid> */}
              {/* <Grid md={6} xs={12} item>
                <FormLabel>
                  <>Select Quote</>
                </FormLabel>

                <ChipDropdown
                  getValues={getValues}
                  state={'selectQuote'}
                  secondstate={'selectQuote_id'}
                  setValue={setValue}
                  data={initialData?.filterquote}
                  register={register}
                  setCondition={setCondition}
                  validation={false}
                  errors={null}
                  checked={'checked'}
                  client="quote"
                />
              </Grid> */}
              {/* <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Quote Amount<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <TextField
                  fullWidth
                  step="0.01"
                  type="number"
                  name="po_amount"
                  min={0}
                  {...register('po_amount', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.po_amount)}
                  helperText={errors.po_amount?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 40 }}
                />
              </Grid> */}
              <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Job Status<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <ChipDropdown
                  fullWidth
                  state={'Status'}
                  secondstate={'Status_id'}
                  setValue={setValue}
                  getValues={getValues}
                  checked={'checked'}
                  data={jobstatusData?.map((item) => {
                    return { label: item?.name, id: item._id };
                  })}
                  register={register}
                  validation={true}
                  errors={errors}
                />
              </Grid>
              {/* <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Select Client<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <ChipDropdown
                  getValues={getValues}
                  state={'selectClient'}
                  secondstate={'selectClient_id'}
                  setValue={setValue}
                  data={initialData?.client}
                  register={register}
                  validation={true}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid> */}

              {/*  aaadddd new clite id */}

              {/* {} */}
              {/* 
              <Grid md={6} xs={12} item>
                <FormLabel>
                  <>
                    Select Client<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    className={errorMassage ? 'error-input-border' : ''}
                    onChange={(e) => {
                      handleChangeField(e);
                    }}
                    value={getValues('selectClient') || ''}
                    defaultValue={getValues('selectClient') || ''}
                    rules={{ required: true }}
                    inputProps={
                      <>
                        <TextField
                          {...register('selectClient', {
                            required: '**Field is required',
                          })}
                          error={Boolean(errorMassage)}
                          helperText={errorMassage ? '*Field is required' : null}
                        />
                      </>
                    }
                    renderValue={(selected) => {
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                          <Chip label={selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                        </Box>
                      );
                    }}
                  >
                    {initialData?.client?.map((name) => {
                      return (
                        <MenuItem
                          key={name.id}
                          selected={personName === name.label}
                          value={`${name.label},${name.id}`}
                          style={getStyles(name.label, personName, theme)}
                        >
                          {name.label === '' ? 'no data found' : name.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid md={6} item xs={12}>
                <FormLabel>
                  <>Client Location</>
                </FormLabel>
                <ChipDropdown
                  state={'clientLocation'}
                  secondstate={'clientLocation_id'}
                  setValue={setValue}
                  getValues={getValues}
                  data={clientlocation.map((client) => ({
                    label: client.name,
                    id: client._id,
                  }))}
                  register={register}
                  validation={true}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid> */}

              <Grid item md={6} xs={12}>
                <FormLabel>
                  <>
                    Sub Contract<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name="subcontract"
                  {...register('subcontract', {
                    required: '*Field is required and needs to be a text',
                  })}
                  error={Boolean(errors.subcontract)}
                  helperText={errors.subcontract?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid md={6} item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>
                    <>Order Date</>
                  </FormLabel>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setValue('orderDate', newValue);
                      setOrderDate(newValue);
                    }}
                    value={orderDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid md={6} item xs={12}>
                <Grid>
                  <FormLabel>
                    <>Remarks</>
                  </FormLabel>
                </Grid>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  {...register(`remark`)}
                  onChange={(e, val) => {
                    settextAreaCount(e.target.value.length);
                  }}
                  inputProps={{ maxLength: 300 }}
                  InputLabelProps={{ shrink: true }}
                />
                <Grid item sx={{ fontSize: '12px', textAlign: 'end' }} xs={12}>
                  {' '}
                  {textAreaCount} / 300
                </Grid>
              </Grid>

              <Grid md={6} item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>
                    <>Due Date</>
                  </FormLabel>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setValue('dueDate', newValue);
                      setDueDate(newValue);
                    }}
                    value={dueDate}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            {/* billing address */}
            <Grid md={12} item xs={12}>
              <Typography variant="h5" component="h6" sx={{ marginTop: '20px' }}>
                Site Address<span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid>
              <Card sx={{ marginBlock: 2, padding: '15px' }}>
                <Box sx={{ marginRight: '15px', marginBottom: '10px' }}>
                  <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                    <Grid sx={{ marginLeft: '20px' }}>
                      <Grid container spacing={{ xs: 0, sm: 0, md: 5 }}>
                        {/* <Grid xs={12} md={6} item mt={2} sx={{ width: '60vw' }}>
                          <FormLabel>
                            <>
                              Location Name <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            name="locationName"
                            {...register(`locations.${0}.locationName`, {
                              required: '*Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.locations ? errors.locations[0]?.locationName : false)}
                            helperText={
                              Boolean(errors.locations ? errors.locations[0]?.locationName : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} md={6} item mt={2} sx={{ width: '60vw' }}>
                          <FormLabel>
                            Munciple Address<span style={{ color: 'red' }}>*</span>{' '}
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            name="muncipleAddress"
                            error={Boolean(errors.locations ? errors.locations[0]?.muncipleAddress : false)}
                            helperText={
                              Boolean(errors.locations ? errors.locations[0]?.muncipleAddress : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            {...register(`locations.${0}.muncipleAddress`, {
                              required: '*Field is required',
                            })}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <ClientaddressDropdown
                          index={0}
                          categoryvalue={setcategoryvalue}
                          getValues={getValues}
                          state={`locations.${0}.stateprovince`}
                          secondstate={`locations.${0}.stateprovince_id`}
                          city={`locations.${0}.city`}
                          secondcity={`locations.${0}.city_id`}
                          setValue={setValue}
                          data={stateData}
                          validation={true}
                          errors={errors}
                          register={register}
                          params={params}
                        />
                      </Grid>
                      <Grid container direction={'row'} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} md={6} mt={2} item>
                          <FormLabel>Postal Code </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            onInput={(e) => {
                              e.target.value = e.target.value.toString()?.slice(0, 50);
                            }}
                            {...register(`locations.${0}.postalCode`)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
              <Grid md={6} item xs={12}>
                <Typography variant="h5" component="h6" sx={{ margintop: '0px' }}>
                  Invoice Line Item Type<span style={{ color: 'red' }}>*</span>
                </Typography>
                <Grid sx={{ display: 'flex' }}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={selected}
                    aria-label="Platform"
                    // {...register("invoiceLineItemType", selected)}
                    onChange={(e, val) => {
                      setValue('invoiceLineItemType', e.target.value);
                      setSelected(e.target.value);
                    }}
                  >
                    <ToggleButton value="Detailed">Detailed</ToggleButton>
                    <ToggleButton value="Semmerized">Semmerized</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid md={6} item xs={12}>
                <Typography variant="h5" component="h6">
                  Taxes Name <span style={{ color: 'red' }}>*</span>
                </Typography>

                <Grid sx={{ display: 'flex' }}>
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={taxes}
                    aria-label="Platform"
                    // {...register("taxesName", taxes)}
                    onChange={(e, val) => {
                      setValue('taxesName', e.target.value);
                      settaxes(e.target.value);
                    }}
                  >
                    <ToggleButton value="TicketInvoice">Ticket / Invoice</ToggleButton>
                    <ToggleButton value="Invoice">Invoice</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Card sx={{ marginBlock: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Taxes" {...a11yProps(0)} />
                    <Tab label="Fees" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Grid mt={0}>
                    <Grid>
                      <Typography variant="h5"> Taxes</Typography>
                    </Grid>
                    {taxItem.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon
                              variant="contained"
                              onClick={() => handleTaxesDelete(index)}
                              sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                              startIcon={<Iconify icon="eva:plus-fill" />}
                            >
                              {''}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '15px' }}>
                            <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                              <Grid md={6} item xs={12} sx={{ width: '60vw' }}>
                                <FormLabel>
                                  <>
                                    taxes Name<span style={{ color: 'red' }}>*</span>
                                  </>
                                </FormLabel>
                                <ChipDropdown
                                  state={`taxItem.${index}.taxesName`}
                                  setValue={setValue}
                                  getValues={getValues}
                                  data={taxdata}
                                  startIcon={<FmdGoodIcon />}
                                  register={register}
                                  validation={true}
                                  errors={errors}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <FormLabel>
                                  <>
                                    Tax (Percentage)<span style={{ color: 'red' }}>*</span>
                                  </>
                                </FormLabel>
                                <TextField
                                  fullWidth
                                  onInput={(e) => {
                                    e.target.value =
                                      e.target.value <= 100 ? e.target.value.toString()?.slice(0, 3) : '';
                                  }}
                                  min={0}
                                  type="number"
                                  name="tax_percentage"
                                  {...register(`taxItem.${index}.tax_percentage`, {
                                    required: '*Field is required and needs to be a text',
                                  })}
                                  error={Boolean(errors.taxItem ? errors.taxItem[index].tax_percentage : null)}
                                  helperText={
                                    Boolean(errors.taxItem ? errors.taxItem[index].tax_percentage : false)
                                      ? '*Field is required and needs to be a text'
                                      : null
                                  }
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Grid>
                      <Button
                        variant="contained"
                        sx={{ marginTop: '20px' }}
                        onClick={handleTaxesButton}
                        startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                      >
                        Add Tax
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid>
                    <Grid mt={0}>
                      <Typography variant="h5"> Fees</Typography>
                    </Grid>
                    {feesItem.map((element, index) => (
                      <>
                        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                          <Grid sx={{ marginRight: '15px' }}>
                            <HighlightOffIcon
                              variant="contained"
                              onClick={() => handleFeesDelete(index)}
                              sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                              startIcon={<Iconify icon="eva:plus-fill" />}
                            >
                              {''}
                            </HighlightOffIcon>
                          </Grid>
                          <Divider orientation="vertical" variant="middle" flexItem />
                          <Grid sx={{ marginLeft: '15px' }}>
                            <Grid container rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                              <Grid md={6} item xs={12} sx={{ width: '60vw' }}>
                                <Grid>
                                  <FormLabel>
                                    <>
                                      Fees Name<span style={{ color: 'red' }}>*</span>
                                    </>
                                  </FormLabel>
                                </Grid>
                                <ChipDropdown
                                  state={`feesItem.${index}.FeesName`}
                                  setValue={setValue}
                                  getValues={getValues}
                                  data={feedata}
                                  startIcon={<FmdGoodIcon />}
                                  register={register}
                                  validation={true}
                                  errors={errors}
                                />
                              </Grid>
                              <Grid md={6} item xs={12}>
                                <Grid>
                                  <FormLabel>
                                    <>
                                      Fees (Percentage)<span style={{ color: 'red' }}>*</span>
                                    </>
                                  </FormLabel>
                                </Grid>
                                <TextField
                                  fullWidth
                                  onInput={(e) => {
                                    e.target.value =
                                      e.target.value <= 100 ? e.target.value.toString()?.slice(0, 3) : '';
                                  }}
                                  min={0}
                                  type="number"
                                  name="fees_percentage"
                                  {...register(`feesItem.${index}.fees_percentage`, {
                                    required: '*Field is required and needs to be a text',
                                  })}
                                  InputLabelProps={{ shrink: true }}
                                  error={Boolean(errors.feesItem ? errors.feesItem[index].fees_percentage : null)}
                                  helperText={
                                    Boolean(errors.feesItem ? errors.feesItem[index].fees_percentage : false)
                                      ? '*Field is required and needs to be a text'
                                      : null
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Grid>
                      <Button
                        variant="contained"
                        sx={{ marginTop: '20px' }}
                        onClick={handleFeesButton}
                        startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                      >
                        Add Fees
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Card>
            </Grid>
            <Grid mt={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      // defaultChecked={params && getValues('active') ? true : false}
                      // checked={getValues('active') ? true : false}
                      checked={checkbox}
                      onChange={(e, val) => {
                        setValue('active', val);
                        setcheckbox(val);
                      }}
                    />
                  }
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{ width: 300, marginLeft: '20px' }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/manager/job/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

JobCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
