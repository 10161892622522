import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import JobCreateForm from './JobCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';

const { PRIVATE_ROUTE } = API_ROUTES;

function ManagerJobForm() {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [clientData, setclientData] = React.useState([]);
  const [managerdata, setmanagerdata] = React.useState([]);
  const [taxData, settaxData] = React.useState([]);
  const [feeData, setfeeData] = React.useState([]);
  const [jobstatusData, setjobstatusData] = React.useState([]);
  const [state, setstate] = React.useState([]);
  const [stateData, setstateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control,
  } = useForm(
    !id
      ? {
          defaultValues: {
            Client: '',
            clientLocation: '',
            projectmanager: '',
            clientLocationLabel: '',
            client_address: '',
            dueDate: new Date().toISOString(),
            orderDate: new Date().toISOString(),
            invoiceLineItemType: 'Detailed',
            taxesName: 'TicketInvoice',
            remark: '',
            Status: '',
            active: true,
          },
        }
      : {
          defaultValues: {
            Client: '',
            clientLocation: '',
            projectmanager: '',
            clientLocationLabel: '',
            client_address: '',
            dueDate: new Date().toISOString(),
            orderDate: new Date().toISOString(),
            invoiceLineItemType: '',
            taxesName: '',
            Status: '',
            remark: '',
            active: true,
          },
        }
  );

  const getJobFormById = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_JOB_BY_ID}/${id}`);

    setstate(data.data[0]);
    data?.data[0]?.tax?.map((element, i) => {
      setValue(`taxItem.${i}.taxesName`, element.taxesName);
      setValue(`taxItem.${i}.tax_percentage`, element.tax_percentage);
    });
    data?.data[0]?.fee?.map((element, i) => {
      setValue(`feesItem.${i}.FeesName`, element.FeesName);
      setValue(`feesItem.${i}.fees_percentage`, element.fees_percentage);
    });
    data?.data[0]?.locations?.map((element, i) => {
      setValue(`locations.${i}.locationName`, element.name);
      setValue(`locations.${i}.muncipleAddress`, element.munciple_address);
      setValue(`locations.${i}.stateprovince`, element.state);
      setValue(`locations.${i}.stateprovince_id`, element.state_id);
      setValue(`locations.${i}.city`, element.city);
      setValue(`locations.${i}.city_id`, element.city_id);
      setValue(`locations.${i}.postalCode`, element.postal_code);
    });
    // setValue('client_project', data?.data[0]?.project_name);
    setValue('PO', data?.data[0]?.po);
    setValue('po_amount', data?.data[0]?.po_amount);
    setValue('Status', data?.data[0]?.status_id?.name);
    setValue('Status_id', data?.data[0]?.status_id?._id);
    setValue('subcontract', data?.data[0]?.sub_contract);
    setValue('orderDate', data?.data[0]?.order_date);
    setValue('dueDate', data?.data[0].due_date);
    setValue('invoiceLineItemType', data?.data[0].invoice_line_item_type);
    setValue('taxesName', data?.data[0].taxes_name);
    setValue('remark', data?.data[0].remark);
    setValue('active', data?.data[0].active);
    setValue('selectClient', data?.data[0]?.clientdata?.company_name);
    setValue('selectClient_id', data?.data[0]?.clientdata?._id);
    setLoading(false);
  }, [authAxios, id, setValue]);

  const getclientdata = useCallback(async () => {
    const taxdata = await authAxios.get(`${PRIVATE_ROUTE.GET_TAXALL}`);
    const feedata = await authAxios.get(`${PRIVATE_ROUTE.GET_FEEALL}`);
    const jobstatusdata = await authAxios.get(`${PRIVATE_ROUTE.GET_JOBSTATUSALL}`);
    settaxData(taxdata.data.data);
    setfeeData(feedata.data.data);
    setjobstatusData(jobstatusdata.data.data);
    const defaultStatus = jobstatusdata?.data?.data?.find((item) => item._id === '659fb1cccc07d74fc15baf4c');
    if (defaultStatus) {
      setValue('Status', defaultStatus?.name);
      setValue('Status_id', defaultStatus?._id);
    }
    setLoading(false);
    const taskInitialData = [];
    // const taskInitialData = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_INITAL_DATA}`);
    setInitialData({
      client: taskInitialData?.data?.data?.client
        ?.filter((item) => item?.active === true)
        .map((iteam) => ({ label: iteam.company_name, id: iteam._id })),
    });
  }, [authAxios, id]);
  const getstatedata = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_STATEALL}`);
    setstateData(data.data);
  });
  useEffect(() => {
    getstatedata();
    getclientdata();
    if (id) {
      getJobFormById();
    }
    return () => {
      getclientdata();
    };
  }, [getJobFormById, id, getclientdata]);

  const onSubmit = async (data) => {
    const newdata = {
      ...data,
      clientLocation: data?.clientLocation?.trim(),
      client: data?.client?.trim(),
      projectManagerid: data?.projectManagerid?.trim(),
    };
    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_JOB}${id}`, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/manager/job/list');
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_JOB, newdata);
      if (response.data.statusCode === 200) {
        setLoading(false);
        toast.success(response.data.massage);
        navigate('/manager/job/list');
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    }
    setValue('Client', '');
    setValue('clientLocation', '');
    setValue('clientLocationLabel', '');
    setValue('client_address', '');
    data?.taxItem?.map((element, i) => {
      setValue(`taxItem.${i}.taxesName`, '');
      setValue(`taxItem.${i}.tax_percentage`, '');
    });
    data?.feesItem?.map((element, i) => {
      setValue(`feesItem.${i}.FeesName`, '');
      setValue(`feesItem.${i}.fees_percentage`, '');
    });
    state?.locations?.map((element, i) => {
      setValue(`locations.${i}.locationName`, '');
      setValue(`locations.${i}.muncipleAddress`, '');
      setValue(`locations.${i}.stateprovince`, '');
      setValue(`locations.${i}.stateprovince_id`, '');
      setValue(`locations.${i}.city`, '');
      setValue(`locations.${i}.city_id`, '');
      setValue(`locations.${i}.postalCode`, '');
    });
    // setValue('client_project', '');
    setValue('PO', '');
    setValue('po_amount', '');
    setValue('Status', '');
    setValue('Status_id', '');
    setValue('subcontract', '');
    setValue('orderDate', '');
    setValue('dueDate', '');
    setValue('invoiceLineItemType', '');
    setValue('taxesName', '');
    setValue('remark', '');
    setValue('active', '');
    setValue('selectClient', '');
    setValue('selectClient_id', '');
  };

  return (
    <>
      {id
        ? loading && (
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )
        : ''}

      <JobCreateForm
        getValues={getValues}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
        setValue={setValue}
        taxData={taxData}
        feeData={feeData}
        editdata={state}
        stateData={stateData}
        jobstatusData={jobstatusData}
        initialData={initialData}
      />
    </>
  );
}

export default ManagerJobForm;
