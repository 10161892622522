import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import InvoiceCreateForm from './InvoiceCreateForm';
import { Backdrop, CircularProgress } from '@mui/material';

const { PRIVATE_ROUTE } = API_ROUTES;

function InvoiceForm() {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
    control,
  } = useForm(
    !id
      ? {
        selectClient: '',
        selectClient_id: '',
      }
      : {
        selectClient: '',
        selectClient_id: '',
      }
  );

  const getReleventData = useCallback(async () => {
    const taskInitialData = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENTALL}`);
    
    setInitialData({
      client: taskInitialData?.data?.data?.filter((item) => item?.active === true).map((iteam) => ({ label: iteam.company_name, id: iteam._id })),
    });
    setLoading(false);
  }, [authAxios]);

  useEffect(() => {
    getReleventData();
  }, [getReleventData]);

  const onSubmit = async (data) => {
    if (!data?.taskIds || !data?.taskIds.length) {
      const msg = "Please select atleast one task";
      toast.error(msg);
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_INVOICE, data);
      if (response.data.statusCode === 200) {
        setValue('selectClient', '');
        setValue('selectClient_id', '');
        setValue('totalCost', '');
        setValue('totalHours', '');
        setValue('taskIds', []);
        toast.success(response.data.massage);
        navigate('/invoice/list');
      } else {
        toast.error(response.data.massage);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        ''
      )}
      <InvoiceCreateForm
        initialData={initialData}
        getValues={getValues}
        watch={watch}
        setValue={setValue}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
      />
    </>
  );
}

export default InvoiceForm