import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Table,
  Stack,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  FormControl,
  InputLabel,
  Select,
  TableFooter,
} from '@mui/material';
// import MUIDataTable from 'mui-datatables';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate, Link } from 'react-router-dom';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import Icon from 'src/components/color-utils/Icon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Chip from '@mui/material/Chip';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import dayjs from 'dayjs';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function AdminsideTasklistComplete() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const [editdisabeld, setEditDisabeld] = useState();
  const { authAxios } = useContext(FetchContext);
  const [sortEnable, setSortEnable] = useState({});
  const [searchEnable, setSearchEnable] = useState();
  const [selectedstartDate, setselectstartDate] = useState(null);
  const [selectendDate, setselectendDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [filterManagerdata, setFilterManagerdata] = useState([]);
  const [manageId, setManageId] = useState('All_Manager');
  const [totalInvoiceCost, setTotalInvoiceCost] = useState(0);
  const [totalBillableCost, setTotalBillableCost] = useState(0);

  const navigate = useNavigate();

  // const handleOpenMenu = (event, id) => {
  //   setOpen(event.currentTarget);
  //   setSelectedRow(id);
  // };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelectedRow(null);
  // };

  const handlejobcreateform = () => {
    navigate('/task/create');
  };

  const handleEditRedirect = (e, id) => {
    setOpen(null);
    setSelectedRow(null);

    navigate(`/task/update/${id}`);
  };

  const handleRowDelete = async (e, id) => {
    if (confirm('Are you sure want to delete?') === true) {
      setLoading(false);
      await authAxios.post(`${PRIVATE_ROUTE?.DELETE_TASK}${id}`);
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
        manageId: manageId,
      });
      setOpen(null);
      setLoading(true);
      setSelectedRow(null);
    }
  };
  const calculateBillableCosts = (item) => {
    const ESTIMATE = item?.ratesheet_id?.billable_line_items;
    const DWRS = item.dwrs;

    return DWRS.filter((dwr_item) => dwr_item.status === 1).flatMap((dwrsEntry) => {
      return dwrsEntry.billing_line_items.labourCosts
        .map((dwrsItem) => {
          const estimateMatch = ESTIMATE.labourItem.find((estimateItem) => estimateItem.costItem === dwrsItem.costitem);

          if (estimateMatch) {
            const unitCost = parseFloat(estimateMatch.unitCost);
            const taskHours = parseFloat(dwrsItem.hours);
            return {
              task_id: dwrsEntry.task_id,
              costitem: dwrsItem.costitem,
              unitCost: unitCost,
              taskHours: taskHours,
              totalCost: unitCost * taskHours,
            };
          }
          return null;
        })
        .filter(Boolean);
    });
  };



  const estimatedBillableCostData = async (query) => {
    setTotalInvoiceCost(0);
    setTotalBillableCost(0);
    let start_date = ""
    let end_date = ""
    if (query) {
      start_date = query?.selectedstartDate !== null ? query.selectedstartDate : '';
      end_date = query?.selectendDate !== null ? query.selectendDate : '';
    }
    let sort_params = `&startDate=${start_date}&endDate=${end_date}`
    try {
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_TASK_TOTAL_ESTIMATED_HOURS}?is_complete=1&user_id=${manageId}${sort_params}`
      );
      setTotalBillableCost(parseFloat(data.totalBillableCost));
      setTotalInvoiceCost(parseFloat(data.totalEstimatedCost));
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    estimatedBillableCostData();
  }, [manageId]);

  const getUserdata = (query) => {
    // setTotalInvoiceCost(0);
    // setTotalBillableCost(0);
    let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    let start_date = selectedstartDate !== null ? selectedstartDate : '';
    let end_date = selectendDate !== null ? selectendDate : '';
    let search_query = query.searchQuery ? encodeURIComponent(query.searchQuery) : '';
    let sortField = sortEnable.sortField;
    let sortOrder = sortEnable.sortOrder;
    let ProjectManger = '';

    // if (query.manageId === "All_Manager") {
    // *** this if is for the by default asc of manager
    //   sortField = query.sortField === undefined ? 'manager' : query.sortField
    //   sortOrder = query.sortOrder === undefined ? 'asc' : query.sortOrder
    // }

    if (query.manageId && query.manageId !== 'All_Manager') {
      // ***this project manager query is for the mui fillter function and first setManageId was null
      // ProjectManger = query.manageId !== null ? (query.manageId !== 'manager' ? query.manageId : '') : '';
      ProjectManger = query.manageId;
    }
    if (typeof query !== 'string') {
      sort_params = `&startDate=${start_date}&endDate=${end_date}&sortField=${sortField}&sortOrder=${sortOrder}&search=${search_query}&user_id=${ProjectManger}`;
    } else {
      sort_params = `&search=${query}&startDate=${start_date}&endDate=${end_date}`;
    }
    let isMounted = true;
    const getUsers = async () => {
      // const { data } = await authAxios.get(
      //   `${PRIVATE_ROUTE?.GET_TASK}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}&is_complete=1`
      // );
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_TASK}?page=${page_cout + 1}&per_page=${rowsPerPage}${sort_params}&is_complete=1`
      );
      // setFilterManagerdata(data?.data)
      if (isMounted) {
        setUsers(
          data?.data?.map((item) => {
            const project_managers_name = item.project_managers.map(
              (pmdetails) => pmdetails.first_name + ' ' + pmdetails.last_name
            );

            const totalOfDwrCost = calculateBillableCosts(item);
            const totalBilebleSum = totalOfDwrCost.reduce((acc, curr) => acc + curr.totalCost, 0);
            // setTotalBillableCost((prevTotal) => prevTotal + parseFloat(totalBilebleSum));
            let invoice = 'No';
            if (item?.is_invoice_generated) {
              // setTotalInvoiceCost((prevTotal) => prevTotal + parseFloat(item?.total_cost_hour));
              invoice = 'Yes';
            }

            return {
              _id: item._id,
              name: item.name,
              task_number: (
                <Link to={`/task/view/${item._id}`}>
                  <b>{item?.number_str}</b>
                </Link>
              ),
              job_number: (
                <Link to={`/job/view/${item.job_id._id}`}>
                  <b>{item?.job_id?.number_str}</b>
                </Link>
              ),
              client_number: (
                <Link to={`/client/view/${item.client_id._id}`}>
                  <b>{item?.client_id?.company_name}</b>
                </Link>
              ),
              quote_number: (
                <Link to={`/quote/view/${item?.quote_id?._id}`}>
                  <b>{item?.quote_id?.number_str}</b>
                </Link>
              ),
              invoice_number: item?.invoice_id ? item?.invoice_id.number_str : '-',
              // status: item?.status_id.name,
              task_scope: item?.task_scope_id?.name,
              is_completed: item?.is_completed,
              ratesheet: item?.ratesheet_id.name,
              manager: project_managers_name,
              active: <Icon color={item.active === true ? 'green' : 'red'} name="people" size={30} />,
              invoiced_amount: item.is_invoice_generated ? item?.invoice_id?.total_cost : `-`,
              billable_cost: totalBilebleSum,
              invoice_generated: invoice,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
        setEditDisabeld(data.data);
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    // getUsers()
    return () => {
      isMounted = false;
    };
  };

  function handleDateSort() {
    if (selectendDate !== null && selectedstartDate !== null) {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        manageId: manageId,
      });
      setLoading(true);
      estimatedBillableCostData({ selectendDate: selectendDate, selectedstartDate: selectedstartDate })
    }
  }

  function handleClearDateSort(selectedstartDate, selectendDate) {
    if (manageId !== 'All_Manager') {
      setIsValid(true);
      setManageId('All_Manager');
    } else if (selectedstartDate !== null && selectendDate === null) {
      setIsValid(false);
    } else if (selectedstartDate == null && selectendDate !== null) {
      setIsValid(false);
    } else if (selectedstartDate === null && selectendDate === null) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    setselectendDate(null);
    setselectstartDate(null);
    setManageId('All_Manager');
  }

  useEffect(() => {
    if (isValid) {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
        manageId: manageId,
      });
    }
    setIsValid(false);
    setLoading(true);
  }, [isValid]);

  function handleChangeManager(e) {
    setLoading(true);
    // if (e.target.value === "All_Manager") {
    //   getUserdata({
    //     sortField: sortEnable.sortField,
    //     sortOrder: sortEnable.sortOrder,
    //     searchQuery: searchEnable,
    //     manageId: e.target.value
    //   });
    // } else {
    getUserdata({
      sortField: sortEnable.sortField,
      sortOrder: sortEnable.sortOrder,
      searchQuery: searchEnable,
      manageId: e.target.value,
    });
    // }
    setManageId(e.target.value);
    // setEmployeeIdValidation();
  }
  // useEffect(() => {
  //   if (manageId !== null) {
  //     setLoading(true);
  //     getUserdata({
  //       sortField: sortEnable.sortField,
  //       sortOrder: sortEnable.sortOrder,
  //       searchQuery: searchEnable,
  //       manageId: manageId !== 'manager' ? manageId : '',
  //     });
  //   }
  // }, [manageId]);

  useEffect(() => {
    if (rowsPerPage * page < count) {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
        manageId: manageId,
      });
    } else {
      getUserdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        manageId: manageId,
      });
    }
    setLoading(true);
  }, [rowsPerPage]);

  const columns = [
    {
      name: '_id',
      label: 'Id',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    // {
    //   name: 'is_completed',
    //   label: 'is_completed',
    //   options: {
    //     draggable: true,
    //     sort: false,
    //     filter: false,
    //     display: false,
    //   },
    // },
    {
      name: 'task_number',
      label: 'Task Number',
      options: {
        draggable: true,
        sort: true,
        filter: false,
        display: true,
      },
    },
    {
      name: `job_number`,
      label: 'Job Number',
      options: {
        draggable: false,
        sort: true,
        filter: false,
        display: true,
      },
    },
    {
      name: 'client_number',
      label: 'Client',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    {
      name: 'invoice_number',
      label: 'Invoice Number',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    // {
    //   name: 'quote_number',
    //   label: 'Quote Number',
    //   options: {
    //     draggable: false,
    //     sort: false,
    //     filter: false,
    //     display: true,
    //   },
    // },
    {
      name: 'manager',
      label: 'Project Manager',
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
        filterOptions: {
          names: [...filterManagerdata.map((manager) => `${manager.first_name} ${manager.last_name}`)],
          fullWidth: true,
        },
        customFilterListRender: (value, filterList, index, column) => {
          console.log('Filtered value: ', value); // Log selected filter values
          console.log('Filter list: ', filterList); // Log filter list for the column
          console.log('Column name: ', column); // Log column details (e.g., 'manager')
          const selectedManager = filterManagerdata.find(
            (manager) => `${manager.first_name} ${manager.last_name}` === value
          );
          if (selectedManager) {
            setManageId(selectedManager._id);
          }
          return <div>{value}</div>;
        },
      },
    },

    // {
    //   name: 'name',
    //   label: 'Name',
    //   options: {
    //     draggable: false,
    //     // sort:true,
    //     filter: true,
    //     display: true,
    //   },
    // },
    {
      name: 'ratesheet',
      label: 'RateSheet',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    {
      name: 'billable_cost',
      label: 'Billable Cost',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    {
      name: 'invoiced_amount',
      label: 'Invoiced Amount',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    {
      name: 'invoice_generated',
      label: 'Invoice Generated',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },

    {
      name: 'task_scope',
      label: 'Task Scope',
      options: {
        draggable: false,
        sort: false,
        filter: false,
        display: true,
      },
    },
    {
      name: `active`,
      label: 'Active',
      options: {
        draggable: false,
        filter: false,
        display: true,
      },
    },
    {
      name: '',
      lable: 'Action',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <IconButton color="inherit" onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}>
            //   <Iconify width={15} icon={'eva:more-vertical-fill'} />
            // </IconButton>
            <div style={{ display: 'flex', gap: '5px' }}>
              <EditIcon
                color={tableMeta.rowData[1] ? 'disabled' : 'inherit'}
                onClick={tableMeta.rowData[1] ? undefined : (e) => handleEditRedirect(e, tableMeta.rowData[0])}
              />
              <DeleteIcon
                color={tableMeta.rowData[1] ? 'disabled' : 'inherit'}
                onClick={tableMeta.rowData[1] ? undefined : (e) => handleRowDelete(e, tableMeta.rowData[0])}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standard',
    print: true,
    filter: false,

    fixedHeader: true,
    searchOpen: true,
    count: count,
    selectableRows: 'none',
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },

    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((item) => {
        if (typeof item === 'string') {
          // If the item is a string, return it directly
          return item;
        }
        if (typeof item === 'object' && Array.isArray(item.data)) {
          const result = [];

          // Loop through each element in item.data
          item.data.forEach((el) => {
            // If the element is a string, push it directly to the result array
            if (typeof el !== 'object') {
              result.push(el);
            }

            // If it's an object, handle extracting specific values
            if (typeof el === 'object') {
              // Check for the job view (for "001004")
              if (el.props?.to) {
                result.push(el.props.children.props.children);
              }

              // Check for manager and location info
              if (Array.isArray(el.props?.children)) {
                const childrenArray = el.props.children;

                const combinedString = `${childrenArray[1]}, ${childrenArray[5]}, ${childrenArray[9]}, ${childrenArray[13]}`;
                result.push(combinedString);

                // result.push(childrenArray[1], childrenArray[5], childrenArray[9], childrenArray[13]); // Push "manager 232" and "New York"
              }
            }
          });

          return result;
        }

        // In case item is neither a string nor an object in the expected format
        return null;
      });
      const result = formattedData.map((data, index) => ({
        index: index,
        data: data,
      }));
      const footerData = ['', '', '', '', 'Total Task Cost', `${totalBillableCost}`, `${totalInvoiceCost}`, ''];
      return `${buildHead(columns)}${buildBody(result)}\n${footerData.join(',')}\n`;
      // return '\uFEFF' + buildHead(columns) + buildBody(result);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'normal',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    onSearchChange: (searchQuery) => {
      setSearchEnable(searchQuery);
      if (searchQuery !== null && searchQuery.length > 2) {
        // getUserdata(searchQuery);

        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getUserdata({ sortField, sortOrder, searchQuery, manageId: manageId }); // Update your API call to include sorting
        } else {
          getUserdata({ searchQuery, manageId: manageId });
        }
        setLoading(true);
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getUserdata({ sortField, sortOrder, manageId: manageId }); // Update your API call to include sorting
        } else {
          getUserdata({ manageId: manageId });
        }
        // getUserdata('');
        setLoading(true);
      }
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      const { name, direction } = tableState.sortOrder;

      const sortField = name; // Column name
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable({ sortField, sortOrder });
        if (searchEnable) {
          getUserdata({ sortField, sortOrder, searchQuery, page_no, manageId: manageId }); // Update your API call to include sorting
        } else {
          getUserdata({ sortField, sortOrder, page_no, manageId: manageId }); // Update your API call to include sorting
        }
        setLoading(true);
      }
      setPage(tableState.page);
      if (action === 'changePage') {
        if (searchEnable) {
          getUserdata({ sortField, sortOrder, searchQuery, page_no, manageId: manageId }); // Update your API call to include sorting
        } else {
          getUserdata({ sortField, sortOrder, page_no, manageId: manageId }); // Update your API call to include sorting
        }
        setLoading(true);
      }
    },
    onFilterChipClose: (val, row, reset) => {
      setManageId('manager');
    },
    onFilterChange: (val, row, reset) => {
      if (row[6].length === 0) {
        setManageId('manager');
      }
      if (reset === 'reset') {
        setManageId('manager');
      }
    },
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          <TableFooter>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: '1px solid #808080' }}>
              {opts.columns.map((col, index) => {
                if (col.name === 'task_number') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'job_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'client_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'invoice_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                // if (col.name === 'quote_number') {
                //   return (
                //     <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                //       { }
                //     </TableCell>
                //   );
                // }
                if (col.name === 'manager') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'ratesheet') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                        textAlign: 'end',
                      }}
                      key={index}
                    >
                      Total:
                    </TableCell>
                  );
                }
                if (col.name === 'billable_cost') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      {totalBillableCost}
                    </TableCell>
                  );
                }
                if (col.name === 'invoiced_amount') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      {totalInvoiceCost}
                    </TableCell>
                  );
                }
                if (col.name === 'status') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'active') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === 'Action') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
                if (col.name === '') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      { }
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableFooter>
        </>
      );
    },
  };

  const managerdata = async (query) => {
    try {
      const response = await authAxios.get(`${PRIVATE_ROUTE?.GET_MANAGER}`);
      const filterEmployRole = response?.data?.data;
      setFilterManagerdata(filterEmployRole);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    managerdata();
  }, []);

  const CustomChip = ({ label, onDelete }) => {
    return <Chip label={label} onDelete={onDelete} sx={{ marginBottom: '10px' }} />;
  };
  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Complete Task
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Task
          </Button>
        </Stack>
        <Grid container xs={10} spacing={1} sx={{ py: 2, alignItems: 'center' }}>
          <Grid item xs={12} md={3} sx={{ paddingTop: '0px !important' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Manager</InputLabel>
              <Select
                label="Select Manager"
                fullWidth
                value={manageId}
                onChange={handleChangeManager}
              // error={employeeIdValidation}
              >
                <MenuItem value={'All_Manager'}>All</MenuItem>
                {filterManagerdata.map((items) => {
                  return (
                    <MenuItem key={items._id} value={items._id}>
                      {`${items.first_name}  ${items.last_name}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                label="Start Date"
                value={selectedstartDate}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectstartDate(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                label="End Date"
                value={selectendDate}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectendDate(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
                minDate={selectedstartDate}
                onError={(newError) => setIsValidDate(newError)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md="auto" sx={{ paddingTop: '0px !important' }}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleDateSort}
              disabled={selectendDate === null || selectedstartDate === null || isValidDate}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} md={3} sx={{ paddingTop: '0px !important' }}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={() => handleClearDateSort(selectedstartDate, selectendDate)}
              disabled={manageId === 'All_Manager' && selectendDate === null && selectedstartDate === null}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </>
                )}
                <>
                  <MUIDataTable
                    options={options}
                    columns={columns}
                    data={users}
                    components={{
                      TableFilterList: CustomFilterList,
                    }}
                  />
                  {/* <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                          px: 1,
                          typography: 'body2',
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleEditRedirect}>
                      <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                      <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                      Delete
                    </MenuItem>
                  </Popover> */}
                </>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
