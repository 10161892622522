import { TextField, Autocomplete, Chip, Select, Box, MenuItem, FormControl, FormHelperText } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { AuthContext } from 'src/context/AuthContext';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
  },
});

const ChipDropdown = ({
  data,
  state,
  setValue,
  getValues,
  register,
  setclientlocation,
  categoryvalue,
  validation,
  secondstate,
  errors,
  checked,
  setCondition,
  params,
  zindex,
  client,
  jobdata,
  searchAbleDropdown,
  rateSheetId,
  setLabourCostData,
  setSelectRateStreet,
  selectRateStreet,
  jobIdValue,
  setJobIdValue,
  removeEquipment,
  removeLabourCosts,
  isAdminDwr,
  editdata,
  setCostItemSelectedIndex,
  cost_item_index,
  setIsDeleting,
  StringComma
}) => {
  const [personName, setPersonName] = React.useState('');

  const { authAxios } = useContext(FetchContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { PRIVATE_ROUTE } = API_ROUTES;

  async function getratesheetdata(id) {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_RATESHEET_BY_ID}/${id}`);
    setLabourCostData(data?.data?.billable_line_items);
  }
  React.useEffect(() => {
    if (rateSheetId === 'getRateSheetId' && getValues(secondstate)) {
      getratesheetdata(getValues(secondstate));
    }
  }, [getValues(secondstate)]);
  function handleDeletechip(e) {
    setValue(secondstate, '');
    setValue(state, '');
    setPersonName([]);
    if (setclientlocation) {
      setValue('client_address', '');
    }
  }
  const handleChangeField = (event) => {
    const {
      target: { value },
    } = event;

    if (cost_item_index > -1) {
      setCostItemSelectedIndex(cost_item_index);
      setIsDeleting(false);
    }
    if (selectRateStreet) {
      setSelectRateStreet('SelectRateStreet');
      setLabourCostData([]);
    }
    setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
    setValue(state, value?.split(',')[0]);
    if (setJobIdValue) {
      setJobIdValue(value?.split(',')[0]);
      setValue('task_name', '');
      removeEquipment();
      removeLabourCosts();
    }

    if (categoryvalue) {
      categoryvalue(value?.split(',')[0]);
      setValue('sub_category', '');
    }
    if (setCondition) {
      setCondition(value?.split(',')[0]);
    }
    if (setclientlocation) {
      setclientlocation(getValues(state));
      setValue('client_address', getValues(state));
    }
    secondstate && setValue(secondstate, value?.split(',')[1].trim());
    secondstate && setPersonName(getValues(state));

    if ((value.match(/,/g) || []).length > 1 && StringComma) {
      setPersonName(typeof value === 'string' ? value.slice(0, value.lastIndexOf(',')) : value);
      setValue(state, value.slice(0, value.lastIndexOf(',')));

      // For the part after the last comma:
      secondstate && setValue(secondstate, value.slice(value.lastIndexOf(',') + 1).trim());
      secondstate && setPersonName(getValues(state));

    }



  };

  const handleChangefields = (event) => {

    if (event) {
      const { label, id } = event;
      let value = `${label},${id} `;
      setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
      setValue(state, value?.split(',')[0]);
      if (categoryvalue) {
        categoryvalue(value?.split(',')[0]);
        setValue('sub_category', '');
      }
      if (setCondition) {
        setCondition(value?.split(',')[0]);
      }
      if (setclientlocation) {
        setclientlocation(getValues(state));
        setValue('client_address', getValues(state));
      }
      if (setJobIdValue) {
        setJobIdValue(value?.split(',')[0]);
        setValue('task_name', '');
        removeEquipment();
        removeLabourCosts();
      }
      secondstate && setValue(secondstate, value?.split(',')[1].trim());
      secondstate && setPersonName(getValues(state));
    }
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  // && errors[`${stateData[0]}`][`${stateData[1]}`] && errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]

  const stateData = state.split('.');
  let errorMassage = false;
  if (!checked) {
    if (
      errors !== null &&
      errors[`${stateData[0]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]
    ) {
      errorMassage = true;
    }
  } else if (errors !== null && errors[`${stateData[0]}`]) {
    errorMassage = true;
  }
  const getDisabled = (val) => {
    if (params) return { disabled: true };
    return {};
  };
  useEffect(() => {
    if (searchAbleDropdown !== 'job_search') {
      if (searchAbleDropdown !== undefined && jobIdValue) {
        const fetchAllData = async () => {
          try {
            const allDataResponse = await authAxios.get(
              `${PRIVATE_ROUTE.GET_TASK_DWR_SEARCH}?search=000&job_id=${jobIdValue}`
            );
            const allData = allDataResponse?.data.data?.map((item) => {
              return { label: item.number_str, id: item._id };
            });
            setOptions(allData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchAllData();
      }
    } else {
      const fetchAllData = async () => {
        setLoading(true);
        try {
          const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_JOB_SEARCH}?search=00`);
          const alldata = data.data?.map((item) => {
            return { label: item.job_number, id: item._id };
          });
          setOptions(alldata);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };

      fetchAllData();
    }
  }, [jobIdValue]); // Replace `third` with the actual dependency, or use `[]` to run only once on mount

  const debouncedSearch = debounce(async (value) => {
    if (value.length >= 3) {
      try {
        setLoading(true);

        if (searchAbleDropdown === 'job_search') {
          const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_JOB_SEARCH}?search=${value}`);
          let alldata = data.data?.map((item) => {
            return { label: item.job_number, id: item._id };
          });
          setOptions(alldata);
        } else {
          const dataa = await authAxios.get(
            `${PRIVATE_ROUTE.GET_TASK_DWR_SEARCH}?search=${value}&job_id=${jobIdValue}`
          );
          let alldata = dataa.data.data?.map((item) => {
            return { label: item.number_str, id: item._id };
          });
          setOptions(alldata);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setOpen(false);
      setLoading(false);
      //   // setOptions([]);
    }
  }, 100);

  return (
    <>
      <FormControl fullWidth>
        {searchAbleDropdown !== undefined ? (
          <>
            <Autocomplete
              className={errorMassage ? 'error-input-border' : ''}
              value={getValues(state) || ''}
              defaultValue={getValues(state) || ''}
              id="search-dropdown"
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                if (options !== '') {
                  setOpen(false);
                }
              }}
              onInputChange={(event, newValue) => {
                setInputValue(newValue);
                debouncedSearch(newValue);
              }}
              onChange={(event, newValue) => {
                handleChangefields(newValue);
              }}
              inputValue={inputValue}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Search"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              inputProps={
                <>
                  {validation ? (
                    <>
                      <TextField
                        {...register(state, {
                          required: '**Field is required',
                        })}
                        error={Boolean(errorMassage)}
                        helperText={Boolean(errorMassage) ? '*Field is required' : null}
                      />
                    </>
                  ) : (
                    <TextField
                      error={Boolean(errorMassage)}
                      helperText={Boolean(errorMassage) ? '*Field is required' : null}
                    />
                  )}
                </>
              }
            />
            <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
          </>
        ) : (
          <>
            {' '}
            <Select
              fullWidth
              className={errorMassage ? 'error-input-border' : ''}
              value={getValues(state) || ''}
              defaultValue={getValues(state) || ''}
              onChange={(e) => {
                handleChangeField(e);
              }}
              disabled={client && getValues('selectQuote') ? true : false}
              {...getDisabled(true)}
              rules={{ required: true }}
              inputProps={
                <>
                  {validation ? (
                    <>
                      <TextField
                        {...register(state, {
                          required: '**Field is required',
                        })}
                        error={Boolean(errorMassage)}
                        helperText={Boolean(errorMassage) ? '*Field is required' : null}
                      />
                    </>
                  ) : (
                    <TextField
                      error={Boolean(errorMassage)}
                      helperText={Boolean(errorMassage) ? '*Field is required' : null}
                    />
                  )}
                </>
              }
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                    <Chip label={StringComma ? selected : selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                  </Box>
                );
              }}
            >
              {data === '' ||
                (data?.length === 0 && (
                  <Box display="flex" alignItems="center" justifyContent="center" marginTop={'3%'}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ))}
              {data?.map((name) => {
                return (
                  <MenuItem
                    key={name.id}
                    selected={personName === name.label}
                    value={`${name.label},${name.id} `}
                    style={getStyles(name.label, personName, theme)}
                  >
                    {name.label === '' ? 'no data found' : name.label}

                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
          </>
        )}
      </FormControl>
    </>
  );
};
export default ChipDropdown;
