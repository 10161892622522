import React, { useEffect, useRef } from 'react';

// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Grid,
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Autocomplete,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Card,
  FormLabel,
} from '@mui/material';
import { DatePicker, LoadingButton, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import PropTypes, { element } from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Dropdown from 'src/components/customecomponent/Dropdown';
import { useNavigate } from 'react-router-dom/dist';
import { Controller, get, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import { useState } from 'react';
import ConditionalDropdown from 'src/components/customecomponent/ConditionalDropdown';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Extend dayjs with timezone and UTC plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export default function DailyWorkReportCreateForm({
  register,
  errors,
  getValues,
  setValue,
  isEdit,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
  watch,
  taskdata,
  costItemData,
  categoryData,
  editdata,
  attachments,
  setAttachments,
  signOne,
  setSignOne,
  signTwo,
  setSignTwo,
  mySignature,
  setMySignature,
  authAxios,
  PRIVATE_ROUTE,
  setJobIdValue,
  jobIdValue,
  joballData,
  setLoading,
  matchIndexes,
}) {
  const navigate = useNavigate();
  const [taskDate, settaskDate] = useState();
  const [checkbox, setcheckbox] = React.useState(true);
  const [labourcheckbox, setLabourcheckbox] = React.useState();
  const [starttime, setStartTime] = useState();
  const [endtime, setEndTime] = useState();
  const [condition, setCondition] = useState(params ? editdata?.task_name : '');
  const [searchAbleDropdown, setsearchAbleDropdown] = useState('dbs');
  const [estimatedhour, setestimatedhour] = React.useState([]);
  let [textAreaCount, settextAreaCount] = React.useState(0);
  const [matchedFilterData, setMatchedFilterData] = React.useState([]);
  const [taskDatas, setTaskDatas] = React.useState([]);


  const [totalExstimatedHours, setTotalExstimatedHours] = React.useState(0);
  const [employeedata, setEmployeedata] = useState([]);
  const sigCanvas = useRef({});
  const inputRef = useRef(null);
  const clear = () => sigCanvas.current.clear();
  const userStaffData = async (query) => {
    try {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_ADMIN}`);
      const filterEmployRole = data?.data;
      //  const user_label = `${filterEmployRole.first_name} ${filterEmployRole.last_name}`
      const emplydata = filterEmployRole?.map(({ _id, first_name, last_name, role }) => ({
        id: _id,
        label: `${first_name} ${last_name}`,
      }));
      setEmployeedata(emplydata);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    userStaffData('');
  }, []);

  const save = (close) => {
    setSignOne(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    const signone = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setValue('representative_sign', signone);
    close();
  };
  function viewRepresentSign(base64URL) {
    if (base64URL.includes('data')) {
      const win = window.open();
      win?.document.write(`<img src="${base64URL}" ></img>`);
    } else {
      let win = window.open();
      win.document.write(
        `<img src="${process.env.REACT_APP_ASSET_URL}/dwr/signature/${base64URL}" frameborder="0"  ></img>`
      );
    }
  }
  function viewRepresentClientSign(base64URL) {
    if (base64URL.includes('data')) {
      const win = window.open();
      win?.document.write(`<img src="${base64URL}" ></img>`);
    } else {
      let win = window.open();
      win.document.write(
        `<img src="${process.env.REACT_APP_ASSET_URL}/dwr/signature/${base64URL}" frameborder="0"  ></img>`
      );
    }
  }

  const saveTwo = (close) => {
    setSignTwo(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    const signtwo = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setValue('client_representative_sign', signtwo);
    close();
  };

  const {
    fields: labourCosts,
    remove: removeLabourCosts,
    insert: insertLabourCosts,
  } = useFieldArray({
    control,
    name: 'labourCosts',
  });
  const {
    fields: equipment,
    remove: removeEquipment,
    insert: insertEquipment,
  } = useFieldArray({
    control,
    name: 'equipment',
  });

  let editstarttime;
  let editendtime;
  editdata?.billing_line_items?.labourCosts?.map((element, i) => {
    editstarttime = element?.start_time;
    editendtime = element?.end_time;
  });

  const edittaskdate = getValues('task_date');
  const editcheckbox = getValues('submit_status');
  const represent = getValues('representative_sign');
  const client_represent = getValues('client_representative_sign');
  useEffect(() => {    
    if (params) {
      // const [datePart] = edittaskdate.split("T");
      if (!isNaN(edittaskdate)) {
        const isoFormattedDate = edittaskdate.toISOString();
        const [datePart] = isoFormattedDate?.split("T");
        settaskDate(datePart || null);
      } else {
        settaskDate(edittaskdate || null);
      }
      // settaskDate(datePart || null);
      setcheckbox(editcheckbox || null);
      setStartTime(editstarttime || null);
      setEndTime(editendtime || null);
    }
  }, [edittaskdate, editcheckbox, editstarttime]);

  React.useEffect(() => {
    const taskName = getValues('task_name');
    const editTaskName = editdata?.taskdata?.number_str;
    async function fetchData() {
      if (editdata && editTaskName !== taskName && matchedFilterData.length !== 0) {
        return;
      }
      const labourItems = taskDatas?.ratesheet_id?.billable_line_items?.labourItem || [];
      const equipmentItems = taskDatas?.ratesheet_id?.billable_line_items?.materialItem || [];

      let matchedData = filterdata?.filter((filterItem) => {
        return labourItems?.some((dataItem) => dataItem.costItem === filterItem.label);
      });

      if (params && editdata?.billable_line_items?.labourCosts?.length !== 0) {
        setMatchedFilterData(matchedData);

        let estimate_hours_arr = [];
        editdata?.billing_line_items?.labourCosts?.map((element, i) => {
          estimate_hours_arr.push(element.hours);
          handlelabourCostsButton();
          handleDelete(labourCosts?.length);
        });
        setestimatedhour(estimate_hours_arr);
      }
      if (params && editdata?.billing_line_items?.equipment?.length !== 0) {
        editdata?.billing_line_items?.equipment?.map((element, i) => {
          handleEquipmentButton();
          handleequipmentDelete(equipment?.length);
        });
      }
    }
    fetchData()
  }, [editdata, taskDatas]);

  const alltask = taskdata
    ?.filter((item) => {
      return !item?.is_completed || item?.is_completed !== 1;
    })
    ?.map((item) => {
      return { label: item?.number_str, id: item._id };
    });
  const filterdata = costItemData
    ?.filter((item) => {
      return item?.category === categoryData[0]?.name;
    })
    ?.map((item) => {
      return { label: item.name, id: item._id };
    });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      const taskId = getValues('task_id');
      const taskName = getValues('task_name');
      const editTaskName = editdata?.taskdata?.number_str;

      if (taskId) {
        const response = await authAxios.get(`${PRIVATE_ROUTE.GET_TASK_BY_ID}/${taskId}`);
        const taskData = response?.data?.data?.[0];
        setTaskDatas(taskData)
        if (editdata && matchedFilterData.length === 0) {
          if (getValues('job') === editdata?.job_number && taskName === editTaskName) {
            return;
          }
        }
        setLoading(true);
        try {
          // const labourItems = taskData?.billing_line_items?.labour_item?.labour_cost_items || [];
          // const equipmentItems = taskData?.billing_line_items?.equipment_item?.equipment_cost_items || [];
          const labourItems = taskData?.ratesheet_id?.billable_line_items?.labourItem || [];
          const equipmentItems = taskData?.ratesheet_id?.billable_line_items?.materialItem || [];
          // Set project managers
          setValue('project_managers_array', taskData?.project_manager);
          setValue('project_manager_id', taskData?.project_manager);

          // Clear previous costs
          removeLabourCosts();
          removeEquipment();

          // Match labour data
          if (labourItems.length) {
            const matchedData = filterdata?.filter((filterItem) =>
              labourItems.some((dataItem) => dataItem.costItem === filterItem.label)
            );
            insertLabourCosts(0, {
              costitem: labourItems[0].costItem || '',
              employee: '',
              employee_name: '',
              start_time: '',
              end_time: '',
              hours: '',
              description: '',
            });
            setMatchedFilterData(matchedData);

            // labourItems.forEach((item, index) => {
            // });
          }

          // Match equipment data
          if (equipmentItems.length) {
            equipmentItems.forEach((item, index) => {
              insertEquipment(index, {
                costitem: item.costItem || '',
                name: '',
                unit: '',
                quantity: '',
                discription: '',
                include_discription: '',
              });
            });
          }
        } catch (error) {
          console.error('Error fetching task:', error); // Handle the error
          setLoading(false);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [getValues('task_id'), insertLabourCosts]);

  const handlelabourCostsButton = () => {
    setEndTime({ ...endtime, [labourCosts.length]: '' });
    setStartTime({ ...starttime, [labourCosts.length]: '' });
    insertLabourCosts(labourCosts.length, {
      costitem: '',
      employee: '',
      employee_name: '',
      start_time: '',
      end_time: '',
      hours: '',
      description: '',
    });
  };
  const handleEquipmentButton = () => {
    insertEquipment(equipment.length, {
      costitem: '',
      name: '',
      unit: '',
      quantity: '',
      discription: '',
      include_discription: '',
    });
  };

  const handleDelete = (i) => {
    removeLabourCosts(i, 1);
  };
  const handleequipmentDelete = (i) => {
    removeEquipment(i, 1);
  };

  const [value, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleDeleteImage = (i) => {
    setSignOne('');
    setValue(`representative_sign`, '');
  };

  const handleDeleteImageTwo = (i) => {
    setSignTwo('');
    setValue('client_representative_sign', '');
  };

  const handleFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setValue(`client_approved_DWR`, reader.result);
      setAttachments(reader.result);
    };
  };

  const handleSignatureFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setSignOne('');
    reader.onloadend = () => {
      setMySignature(reader.result);
      setValue(`representative_sign`, reader.result);
    };
  };

  const handleDeleteSignatureFile = () => {
    setMySignature('');
    setValue(`representative_sign`, '');
  };

  const handleDeleteFile = () => {
    setAttachments('');
    setValue(`client_approved_DWR`, '');
  };
  const calculateTotalHours = (index, newValue) => {
    setestimatedhour((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  };

  const totalEstimatedHour = estimatedhour.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue);
  }, 0);

  useEffect(() => {
    setValue('task_hour', totalEstimatedHour);
    setTotalExstimatedHours(totalEstimatedHour);
  }, [setValue, totalEstimatedHour]);

  function handleviewimage(base64Url) {
    if (base64Url.includes('data')) {
      const win = window.open();
      win?.document.write(`<img src="${base64Url}" ></img>`);
    } else {
      const win = window.open();
      win?.document.write(
        `<div style="display:flex;justify-content:center;margin-top:100px;"><img src="${process.env.REACT_APP_ASSET_URL}/dwr/attachments/${base64Url}"   ></img> </div>`
      );
    }
  }

  const jobfilterdata = taskdata?.filter((iteam) => iteam?.number_str === watch('task_name'))[0];

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {params ? (
          <>
            {' '}
            <Typography variant="h4" component="h2">
              Edit DWR Report
            </Typography>{' '}
          </>
        ) : (
          <>
            {' '}
            <Typography variant="h4" component="h2">
              Create DWR Report
            </Typography>{' '}
          </>
        )}
        <Grid>
          <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid md={6} item xs={12}>
              <FormLabel>
                <>
                  Select Job
                  {/* {/ <span style={{ color: 'red' }}>*</span> /} */}
                </>
              </FormLabel>
              <ChipDropdown
                state={'job'}
                secondstate={'job_id'}
                setValue={setValue}
                getValues={getValues}
                data={joballData?.map((item) => {
                  return { label: item?.job_number, id: item._id };
                })}
                // data={alltask}
                register={register}
                validation={true}
                errors={errors}
                checked={'checked'}
                jobIdValue={jobIdValue}
                setJobIdValue={setJobIdValue}
                removeEquipment={removeEquipment}
                removeLabourCosts={removeLabourCosts}
                searchAbleDropdown={'job_search'}
              />
              {/* <ChipDropdown
                state={'job'}
                secondstate={'job_id'}
                setValue={setValue}
                getValues={getValues}
                data={joballData?.map((item) => {
                  return { label: item?.job_number, id: item._id };
                })}
                register={register}
                validation={false}
                errors={errors}
                checked={'checked'}
                jobIdValue={jobIdValue}
                setJobIdValue={setJobIdValue}
                removeEquipment={removeEquipment}
                removeLabourCosts={removeLabourCosts}
              /> */}
            </Grid>
            {jobIdValue && (
              <Grid md={6} item xs={12}>
                <FormLabel>Task</FormLabel>
                <ChipDropdown
                  getValues={getValues}
                  state={'task_name'}
                  secondstate={'task_id'}
                  setValue={setValue}
                  data={alltask}
                  register={register}
                  validation={true}
                  setCondition={setCondition}
                  errors={errors}
                  checked={'checked'}
                  jobIdValue={jobIdValue}
                  searchAbleDropdown={searchAbleDropdown}
                  editdata={editdata}
                />
              </Grid>
            )}
            {condition === '' ? (
              ''
            ) : (
              <>
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>Date </>
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      onChange={(newValue) => {
                        const convertedDate = dayjs(newValue).format('YYYY-MM-DD');
                        setValue('task_date', convertedDate);
                        settaskDate(convertedDate);
                      }}
                      value={taskDate}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid md={6} item xs={12}>
                  {/* <FormLabel>Task Hours</FormLabel> */}
                  <TextField
                    fullWidth
                    type="number"
                    name="task_hours"
                    {...register(`task_hour`)}
                    InputLabelProps={{ shrink: true }}
                    hidden
                  />
                </Grid>
              </>
            )}
          </Grid>
          {condition === '' ? (
            ''
          ) : (
            <>
              <Grid container direction={'row'} rowSpacing={2} mt={0} spacing={{ xs: 0, sm: 0, md: 5 }}>
                { }
              </Grid>
              <Grid>
                <Typography variant="h6" component="h2" mt={2}>
                  DWR Details
                </Typography>
                <Card sx={{ marginBlock: 2 }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Labour Costs" {...a11yProps(0)} />
                      <Tab label="Equipment And Materials" {...a11yProps(1)} />
                      <Tab label="Job Details" {...a11yProps(2)} />
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>
                    <Grid mt={0}>
                      <Grid>
                        <Typography variant="h6" component="h2">
                          {' '}
                          Labour Cost Line Item
                        </Typography>
                      </Grid>
                      {labourCosts?.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              {labourCosts.length !== 1 && (
                                <HighlightOffIcon
                                  variant="contained"
                                  onClick={() => {
                                    setValue('task_hour', totalExstimatedHours - parseFloat(estimatedhour[index] ? estimatedhour[index] : 0));
                                    setTotalExstimatedHours(totalExstimatedHours - parseFloat(estimatedhour[index] ? estimatedhour[index] : 0));
                                    removeLabourCosts(index);
                                  }}
                                  sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </HighlightOffIcon>
                              )}
                            </Grid>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{ borderColor: matchIndexes.includes(index) ? 'red' : '' }}
                            />
                            <Grid sx={{ marginLeft: '20px', width: '90%' }}>
                              <Grid container direction={'row'} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid md={6} item xs={12}>
                                  <FormLabel>Cost Item</FormLabel>
                                  <ChipDropdown
                                    sx={{ width: '50%' }}
                                    getValues={getValues}
                                    state={`labourCosts.${index}.costitem`}
                                    setValue={setValue}
                                    data={matchedFilterData}
                                    register={register}
                                    validation={true}
                                    errors={errors}
                                  />
                                </Grid>

                                <Grid md={6} item xs={12}>
                                  <FormLabel>Employee</FormLabel>
                                  <ChipDropdown
                                    sx={{ width: '50%' }}
                                    getValues={getValues}
                                    state={`labourCosts.${index}.employee_name`}
                                    secondstate={`labourCosts.${index}.employee`}
                                    setValue={setValue}
                                    data={employeedata}
                                    register={register}
                                    validation={true}
                                    errors={errors}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container direction={'row'} rowSpacing={0} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid item xs={12}>
                                  {matchIndexes.includes(index) && (
                                    <Typography variant="h6" component="h2" sx={{ color: 'red', marginTop: '0px' }}>
                                      Duplicate entry found
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid mt={2}>
                                <Typography variant="h6" component="h2">
                                  {' '}
                                  Time Card
                                </Typography>
                              </Grid>
                              <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Stack>
                                    <Grid
                                      container
                                      direction={'row'}
                                      mt={1}
                                      rowSpacing={2}
                                      spacing={{ xs: 0, sm: 0, md: 5 }}
                                    >
                                      <Grid md={6} item xs={12}>
                                        <FormLabel>Hours</FormLabel>
                                        <TextField
                                          fullWidth
                                          type="number"
                                          name="hours"
                                          {...register(`labourCosts.${index}.hours`)}
                                          onChange={(e) => {
                                            calculateTotalHours(index, e.target.value);
                                            setValue(`labourCosts.${index}.hours`, e.target.value);
                                            inputRef.current?.focus();
                                          }}
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </Grid>
                                      <Grid md={6} item xs={12}>
                                        <FormLabel>Description</FormLabel>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          name="description"
                                          {...register(`labourCosts.${index}.description`)}
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </LocalizationProvider>
                              </Grid>

                              <Divider
                                sx={{ borderColor: matchIndexes.includes(index) ? 'red' : '', marginTop: '15px' }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ))}
                      <Grid>
                        <Button
                          variant="contained"
                          sx={{ marginTop: '20px' }}
                          onClick={handlelabourCostsButton}
                          startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                        // disabled={labourCosts?.length === matchedFilterData?.length}
                        >
                          Add Labour Cost
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid>
                      <Grid mt={0}>
                        <Typography variant="h5"> Equipment And Materials</Typography>
                      </Grid>
                      {equipment?.map((element, index) => (
                        <>
                          <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                            <Grid sx={{ marginRight: '15px' }}>
                              <HighlightOffIcon
                                variant="contained"
                                onClick={() => removeEquipment(index)}
                                sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                                startIcon={<Iconify icon="eva:plus-fill" />}
                              >
                                {''}
                              </HighlightOffIcon>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid sx={{ marginLeft: '15px' }}>
                              <Grid container direction={'row'} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                <Grid md={6} item xs={12}>
                                  <FormLabel>Cost Item</FormLabel>
                                  <ChipDropdown
                                    sx={{ width: '50%' }}
                                    getValues={getValues}
                                    state={`equipment.${index}.costitem`}
                                    setValue={setValue}
                                    data={filterdata}
                                    register={register}
                                    validation={true}
                                    errors={errors}
                                  />
                                </Grid>
                                {/* <Grid md={6} item xs={12}>
                                  <FormLabel>Name</FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name="name"
                                    {...register(`equipment.${index}.name`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid> */}
                                <Grid md={6} item xs={12}>
                                  <FormLabel>Unit</FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name="unit"
                                    {...register(`equipment.${index}.unit`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid md={6} item xs={12}>
                                  <FormLabel>Quantity</FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name="quantity"
                                    {...register(`equipment.${index}.quantity`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid md={6} item xs={12}>
                                  <FormLabel>Description</FormLabel>
                                  <TextField
                                    fullWidth
                                    type="text"
                                    name="discription"
                                    {...register(`equipment.${index}.discription`)}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid mt={2}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={getValues(`equipment.${index}.include_discription`)}
                                        defaultChecked={
                                          params && editdata?.billing_line_items?.equipment[index]?.include_discription
                                        }
                                        onChange={(e, val) => {
                                          setValue(`equipment.${index}.include_discription`, val);
                                          setLabourcheckbox(`equipment.${index}.include_discription`, val);
                                        }}
                                      />
                                    }
                                    label="Include Description on DWR"
                                  />
                                </FormGroup>
                              </Grid>
                              <Divider sx={{ marginTop: '15px' }} />
                            </Grid>
                          </Grid>
                        </>
                      ))}
                      <Grid>
                        <Button
                          variant="contained"
                          sx={{ marginTop: '20px' }}
                          onClick={handleEquipmentButton}
                          startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                        >
                          Add Equipment And Materials
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid mt={0}>
                      <Grid>
                        <Typography variant="h5"> Job Details</Typography>
                      </Grid>

                      <Grid sx={{ marginLeft: '15px' }}>
                        <>
                          <Grid md={6} item mt={2} sx={{ width: '60vw' }}>
                            <Grid>
                              <Typography variant="h6"> Job Number</Typography>
                              <Typography variant="h7" mt={1}>
                                {jobfilterdata?.job_id?.number_str}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Client
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {' '}
                                {jobfilterdata?.client_id?.company_name}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Client Address
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {' '}
                                {jobfilterdata?.job_id?.client_address}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Project Name
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {' '}
                                {jobfilterdata?.job_id?.client_project}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Client Project #
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {jobfilterdata?.job_id?.project_manager}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                SubContract #
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {jobfilterdata?.job_id?.sub_contract}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Invoice Line Item Type
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {' '}
                                {jobfilterdata?.job_id?.invoice_line_item_type}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="h6" mt={2}>
                                {' '}
                                Addition Fields
                              </Typography>
                              <Typography variant="h7" mt={1}>
                                {' '}
                                001001
                              </Typography>
                            </Grid>

                            <Divider sx={{ marginTop: '15px' }} />
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Card>
                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                  <Grid md={6} item xs={12}>
                    <Grid>
                      <Typography variant="h6" component="h2">
                        Representative Signature
                      </Typography>
                    </Grid>
                    {signOne !== '' ? (
                      <>
                        <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid md={3} item xs={12} mt={2}>
                            <Button variant="contained" component="label" onClick={() => viewRepresentSign(represent)}>
                              view image
                            </Button>
                          </Grid>
                          <Grid md={3} item xs={12} mt={2}>
                            <Button variant="contained" component="label" onClick={() => handleDeleteImage()}>
                              Delete
                            </Button>
                          </Grid>
                          <Grid md={6} item xs={12}>
                            {''}
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <>
                            <Grid md={12} item xs={12} mt={2}>
                              {mySignature !== '' ? (
                                <Grid sx={{ display: 'flex' }}>
                                  <Grid md={6} item xs={12} mb={2}>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() => handleviewimage(mySignature)}
                                    >
                                      <a
                                        // href={getValues(`client_approved_DWR`)}
                                        rel="noreferrer"
                                        style={{ textDecoration: 'none', color: 'white' }}
                                        target={'_blank'}
                                        label="View Image"
                                      >
                                        view image
                                      </a>
                                    </Button>
                                  </Grid>
                                  <Grid md={6} item xs={12}>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() => handleDeleteSignatureFile()}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : (
                                <>
                                  <Grid md={6} item xs={12} mb={2}>
                                    <Button variant="contained" startIcon={<AddIcon />} component="label">
                                      Add My Signature{' '}
                                      <input
                                        hidden
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        onChange={(e) => handleSignatureFile(e)}
                                      />
                                    </Button>
                                  </Grid>
                                  OR
                                  <Grid md={6} item xs={12} mb={2}>
                                    <Popup
                                      modal
                                      closeOnDocumentClick={false}
                                      trigger={
                                        <Button variant="contained" startIcon={<ChevronRightIcon />}>
                                          Collect Signature{' '}
                                        </Button>
                                      }
                                    >
                                      {(close) => (
                                        <>
                                          <SignaturePad
                                            ref={sigCanvas}
                                            canvasProps={{
                                              className: 'signatureCanvas',
                                            }}
                                          />
                                          <Button variant="contained" sx={{ mr: 2 }} onClick={() => save(close)}>
                                            save
                                          </Button>
                                          <Button variant="contained" sx={{ mr: 2 }} onClick={close}>
                                            close
                                          </Button>
                                          <Button variant="contained" sx={{ mr: 2 }} onClick={clear}>
                                            clear
                                          </Button>
                                        </>
                                      )}
                                    </Popup>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid md={6} item xs={12}>
                    <Grid>
                      <Typography variant="h6" component="h2">
                        Client Representative Signature
                      </Typography>
                    </Grid>

                    {signTwo !== '' ? (
                      <>
                        <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                          <Grid md={3} item xs={12} mt={2}>
                            <Button
                              variant="contained"
                              component="label"
                              onClick={() => viewRepresentClientSign(client_represent)}
                            >
                              view image
                            </Button>
                          </Grid>
                          <Grid md={3} item xs={12} mt={2}>
                            <Button variant="contained" component="label" onClick={() => handleDeleteImageTwo()}>
                              Delete
                            </Button>
                          </Grid>
                          {/* <Grid md={2} item xs={12}>{''}</Grid> */}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item mt={2}>
                          <Popup
                            modal
                            closeOnDocumentClick={false}
                            trigger={
                              <Button variant="contained" startIcon={<ChevronRightIcon sx={{ fontSize: '42px' }} />}>
                                Collect Signature{' '}
                              </Button>
                            }
                          >
                            {(close) => (
                              <>
                                <SignaturePad
                                  ref={sigCanvas}
                                  canvasProps={{
                                    className: 'signatureCanvas',
                                  }}
                                />
                                <Button variant="contained" sx={{ mr: 2 }} onClick={() => saveTwo(close)}>
                                  save
                                </Button>
                                <Button variant="contained" sx={{ mr: 2 }} onClick={close}>
                                  close
                                </Button>
                                <Button variant="contained" sx={{ mr: 2 }} onClick={clear}>
                                  clear
                                </Button>
                              </>
                            )}
                          </Popup>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {jobfilterdata?.client_contacts?.length > 1 ? (
                  <Grid container direction={'row'} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                    <Grid md={6} item xs={12}>
                      <FormLabel>Client's Representative</FormLabel>

                      <ChipDropdown
                        getValues={getValues}
                        state={'client_representative'}
                        secondstate={'client_representative_id'}
                        setValue={setValue}
                        data={jobfilterdata?.client_contacts?.map((item) => {
                          return { label: item?.first_name + ' ' + item?.last_name, id: item?._id };
                        })}
                        register={register}
                        validation={false}
                        errors={null}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid mt={2}>
                  <Typography variant="h6" component="h2">
                    Client-Approved DWR
                  </Typography>
                </Grid>
                <Grid mt={2}>
                  {attachments !== '' ? (
                    <>
                      <Grid container direction={'row'} rowSpacing={5} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid md={6} item xs={12}>
                          <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid md={3} item xs={12} mb={2}>
                              <Button
                                variant="contained"
                                component="label"
                                onClick={() => handleviewimage(attachments)}
                              >
                                <a
                                  // href={getValues(`client_approved_DWR`)}
                                  rel="noreferrer"
                                  style={{ textDecoration: 'none', color: 'white' }}
                                  target={'_blank'}
                                  label="View Image"
                                >
                                  view image
                                </a>
                              </Button>
                            </Grid>
                            <Grid md={3} item xs={12}>
                              <Button variant="contained" component="label" onClick={() => handleDeleteFile()}>
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid md={6} item xs={12}>
                            {''}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Button variant="contained" component="label">
                        select file
                        <input hidden accept="image/*" multiple type="file" onChange={(e) => handleFile(e)} />
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid>
                  <Grid mt={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <>
                            <Checkbox
                              // defaultChecked={params && getValues('submit_status') ? true : false}
                              // checked={getValues('submit_status') ? true : false}
                              checked={checkbox}
                              onChange={(e, val) => {
                                setValue('submit_status', val);
                                setcheckbox(val);
                              }}
                            />
                          </>
                        }
                        label="Submitted"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid mt={2}>
                    <Grid>
                      {!params &&
                        <>
                          <Typography variant="h6" component="h2">
                            Submitted Date
                          </Typography>
                          <Typography variant="h8">{new Date().toISOString().split('T')[0]}</Typography>
                        </>
                      }
                      <Grid md={3} item xs={3} className="d-none">
                        <TextField
                          fullWidth
                          name="task_hours"
                          {...register(`project_manager_id`)}
                          value={jobfilterdata?.project_manager}
                          InputLabelProps={{ shrink: true }}
                          hidden
                        />
                        <TextField
                          fullWidth
                          name="task_hours"
                          {...register(`project_managers_array`)}
                          value={jobfilterdata?.project_manager}
                          InputLabelProps={{ shrink: true }}
                          hidden
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid md={6} item xs={12} mt={2}>
                  <FormLabel>
                    <>Remarks</>
                  </FormLabel>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    {...register(`remark`)}
                    onChange={(e, val) => {
                      settextAreaCount(e.target.value.length);
                    }}
                    inputProps={{ maxLength: 300 }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Grid item sx={{ fontSize: '12px', textAlign: 'end' }} xs={12}>
                    {' '}
                    {textAreaCount} / 300
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
          {params && (
            <Button
              variant="contained"
              size="large"
              color="error"
              style={{ width: 300, marginLeft: '20px' }}
              onClick={() => {
                navigate('/manager/dwr/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}
DailyWorkReportCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
