import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  TableFooter,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import Icon from 'src/components/color-utils/Icon';
import { AuthContext } from 'src/context/AuthContext';
import { toast } from 'react-hot-toast';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagersideTasklist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [sortEnable, setSortEnable] = useState({});
  const [searchEnable, setSearchEnable] = useState();
  const { state } = useContext(AuthContext);
  const [selectedstartDate, setselectstartDate] = useState(null);
  const [selectendDate, setselectendDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [dwrCost, setDwrCost] = useState(0);

  const navigate = useNavigate();
  // const handleOpenMenu = (event, id) => {
  //   setOpen(event.currentTarget);
  //   setSelectedRow(id);
  // };
  const handleEditRedirect = (e, id) => {
    // setOpen(null);
    // setSelectedRow(null);
    navigate(`/manager/task/update/${id}`);
  };
  const handleRowDelete = async (e, id) => {
    if (confirm('Are you sure want to delete?') === true) {
      const deleteData = await authAxios.post(`${PRIVATE_ROUTE?.DELETE_TASK}${id}`);
      if (deleteData?.data?.statusCode === 400) {
        return toast.error(deleteData.data.message);
      }
      getTaskdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
      });
      setOpen(null);
      setSelectedRow(null);
    }
  };
  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelectedRow(null);
  // };

  useEffect(() => {
    const estimatedBillableCostData = async (query) => {
      try {
        const { data } = await authAxios.get(
          `${PRIVATE_ROUTE?.GET_TASK_TOTAL_ESTIMATED_HOURS}?user_id=${state.user._id}`
        );
        setDwrCost(parseFloat(data.totalBillableCost));
        setEstimatedCost(parseFloat(data.totalEstimatedCost));
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };
    if (state) {
      estimatedBillableCostData();
    }
  }, [state]);

  const calculateBillableCosts = (item) => {
    const ESTIMATE = item?.ratesheet_id?.billable_line_items;
    const DWRS = item.dwrs;

    return DWRS.filter((dwr_item) => dwr_item.status === 1).flatMap((dwrsEntry) => {
      return dwrsEntry.billing_line_items.labourCosts
        .map((dwrsItem) => {
          const estimateMatch = ESTIMATE.labourItem.find((estimateItem) => estimateItem.costItem === dwrsItem.costitem);

          if (estimateMatch) {
            const unitCost = parseFloat(estimateMatch.unitCost);
            const taskHours = parseFloat(dwrsItem.hours);
            return {
              task_id: dwrsEntry.task_id,
              costitem: dwrsItem.costitem,
              unitCost: unitCost,
              taskHours: taskHours,
              totalCost: unitCost * taskHours,
            };
          }
          return null;
        })
        .filter(Boolean);
    });
  };

  const handlejobcreateform = () => {
    navigate('/manager/task/create');
  };
  const getTaskdata = (query) => {
    // setEstimatedCost(0);
    // setDwrCost(0);
    let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    let start_date = selectedstartDate !== null ? selectedstartDate : '';
    let end_date = selectendDate !== null ? selectendDate : '';
    let search_query = query.searchQuery ?encodeURIComponent(query.searchQuery) : '';
    if (typeof query !== 'string') {
      sort_params = `&startDate=${start_date}&endDate=${end_date}&sortField=${query.sortField}&sortOrder=${query.sortOrder}&search=${search_query}`;
    } else {
      sort_params = `&search=${query}&startDate=${start_date}&endDate=${end_date}`;
    }
    let isMounted = true;
    const getTasks = async () => {
      // const { data } = await authAxios.get(
      //   `${PRIVATE_ROUTE?.GET_TASK}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}&is_complete=0`
      // );
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_TASK}?page=${page_cout + 1}&per_page=${rowsPerPage}${sort_params}&is_complete=0&user_id=${
          state.user._id
        }`
      );
      if (isMounted) {
        setTasks(
          data?.data?.map((item) => {

            const totalOfDwrCost = calculateBillableCosts(item);
            const totalBilebleSum = totalOfDwrCost.reduce((acc, curr) => acc + curr.totalCost, 0);

            const project_managers_name = item.project_managers.map(
              (pmdetails) => pmdetails.first_name + ' ' + pmdetails.last_name
            );
            // setEstimatedCost((prevTotal) => prevTotal + parseFloat(item?.total_cost_hour ? item.total_cost_hour : 0));
            // setDwrCost((prevTotal) => prevTotal + parseFloat(totalBilebleSum));
            return {
              _id: item._id,
              name: item.name,
              task_number: (
                <Link to={`/manager/task/view/${item._id}`}>
                  <b>{item.number_str}</b>
                </Link>
              ),
              job_number: (
                <Link to={`/manager/job/view/${item.job_id._id}`}>
                  <b>{item.job_id.number_str}</b>
                </Link>
              ),
              client_number: item.client_id.company_name,
              quote_number: (
                <Link to={`/manager/quote/view/${item?.quote_id?._id}`}>
                  <b>{item?.quote_id?.number_str}</b>
                </Link>
              ),
              // status: item.status_id.name,
              task_scope: item?.task_scope_id?.name,
              estimated_cost: item.total_cost_hour,
              dwrs_hours_cost: totalBilebleSum,
              ratesheet: item.ratesheet_id.name,
              manager: project_managers_name.join(', '),
              active: <Icon color={item.active === true ? 'green' : 'red'} name="people" size={30} />,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
      }
    };
    getTasks().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    // getUsers()
    return () => {
      isMounted = false;
    };
  };

  function handleDateSort() {
    if (selectendDate !== null && selectedstartDate !== null) {
      setLoading(true);
      getTaskdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
      });
    }
  }
  function handleClearDateSort(selectedstartDate, selectendDate) {
    if (selectedstartDate !== null && selectendDate === null) {
      setIsValid(false);
    } else if (selectedstartDate == null && selectendDate !== null) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    setselectendDate(null);
    setselectstartDate(null);
  }

  useEffect(() => {
    if (isValid) {
      getTaskdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
      });
    }
    setIsValid(false);
    setLoading(true);
  }, [isValid]);

  useEffect(() => {
    if (rowsPerPage * page < count) {
      getTaskdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
        page_no: page,
      });
    } else {
      getTaskdata({
        sortField: sortEnable.sortField,
        sortOrder: sortEnable.sortOrder,
        searchQuery: searchEnable,
      });
    }
    setLoading(true);
  }, [rowsPerPage]);

  const columns = [
    {
      name: '_id',
      label: 'Id',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'task_number',
      label: 'Task Number',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: `job_number`,
      label: 'Job Number',
      options: {
        draggable: false,
        // sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'client_number',
      label: 'Client',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    // {
    //   name: 'quote_number',
    //   label: 'Quote Number',
    //   options: {
    //     draggable: false,
    //     sort: false,
    //     filter: true,
    //     display: true,
    //   },
    // },
    {
      name: 'manager',
      label: 'Project Manager',
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      },
    },
    // {
    //   name: 'name',
    //   label: 'Name',
    //   options: {
    //     draggable: false,
    //     // sort:true,
    //     filter: true,
    //     display: true,
    //   },
    // },
    {
      name: 'ratesheet',
      label: 'RateSheet',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'estimated_cost',
      label: 'Estimated Cost',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'dwrs_hours_cost',
      label: 'Dwrs Hours Cost',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'task_scope',
      label: 'Task Scope',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: `active`,
      label: 'Active',
      options: {
        draggable: false,
        filter: true,
        sort: false,
        display: true,
      },
    },
    {
      name: '',
      lable: 'Action',
      options: {
        filter: true,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // <IconButton color="inherit" onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}>
            //   <Iconify width={15} icon={'eva:more-vertical-fill'} />
            // </IconButton>
            <div style={{ display: 'flex', gap: '5px' }}>
              <EditIcon onClick={(e) => handleEditRedirect(e, tableMeta.rowData[0])} />
              <DeleteIcon onClick={(e) => handleRowDelete(e, tableMeta.rowData[0])} />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    print: true,
    filter: false,
    fixedHeader: true,
    searchOpen: true,
    count: count,
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((item) => {
        if (typeof item === 'string') {
          // If the item is a string, return it directly
          return item;
        }
        if (typeof item === 'object' && Array.isArray(item.data)) {
          const result = [];

          // Loop through each element in item.data
          item.data.forEach((el) => {
            // If the element is a string, push it directly to the result array
            if (typeof el !== 'object') {
              result.push(el);
            }

            // If it's an object, handle extracting specific values
            if (typeof el === 'object') {
              // Check for the job view (for "001004")
              if (el.props?.to) {
                result.push(el.props.children.props.children);
              }

              // Check for manager and location info
              if (Array.isArray(el.props?.children)) {
                const childrenArray = el.props.children;

                const combinedString = `${childrenArray[1]}, ${childrenArray[5]}, ${childrenArray[9]}, ${childrenArray[13]}`;
                result.push(combinedString);

                // result.push(childrenArray[1], childrenArray[5], childrenArray[9], childrenArray[13]); // Push "manager 232" and "New York"
              }
            }
          });

          return result;
        }

        // In case item is neither a string nor an object in the expected format
        return null;
      });
      const result = formattedData.map((data, index) => ({
        index: index,
        data: data,
      }));
      const footerData = ['', '', '', 'Total : ', `${estimatedCost}`, `${dwrCost}`];

      return `${buildHead(columns)}${buildBody(result)}\n${footerData.join(',')}\n`;
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    onSearchChange: (searchQuery) => {
      setSearchEnable(searchQuery);
      if (searchQuery !== null && searchQuery.length > 2) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder;
          getTaskdata({ sortField, sortOrder, searchQuery }); // Update your API call to include sorting
        } else {
          getTaskdata({ searchQuery });
        }
        setLoading(true);
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder;
          getTaskdata({ sortField, sortOrder }); // Update your API call to include sorting
        } else {
          getTaskdata('');
        }
        setLoading(true);
      }
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,

    onTableChange: (action, tableState) => {
      const { name, direction } = tableState.sortOrder;
      const sortField = name; // Column name
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable({ sortField, sortOrder });
        if (searchEnable) {
          getTaskdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getTaskdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
        setLoading(true);
      }
      setPage(tableState.page);
      if (action === 'changePage') {
        if (searchEnable) {
          getTaskdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getTaskdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
        setLoading(true);
      }
    },

    customTableBodyFooterRender: (opts) => {
      return (
        <>
          <TableFooter>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: '1px solid #808080' }}>
              {opts.columns.map((col, index) => {
                if (col.name === 'dwr_number') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                      key={index}
                    >
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'task_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'job_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'client_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'quote_number') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'manager') {
                  return <TableCell key={index}>{}</TableCell>;
                }
                if (col.name === 'ratesheet') {
                  return (
                    <TableCell
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                        textAlign: 'end',
                      }}
                      key={index}
                    >
                      Total :
                    </TableCell>
                  );
                }
                if (col.name === 'estimated_cost') {
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                    >
                      {estimatedCost}
                    </TableCell>
                  );
                }
                if (col.name === 'dwrs_hours_cost') {
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        border: '1px solid #808080 !important',
                      }}
                    >
                      {dwrCost}
                    </TableCell>
                  );
                }
                if (col.name === 'status') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === 'active') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
                if (col.name === '') {
                  return (
                    <TableCell sx={{ border: '1px solid #808080 !important' }} key={index}>
                      {}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableFooter>
        </>
      );
    },
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Active Task
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Task
          </Button>
        </Stack>
        <Grid container xs={8} spacing={2} sx={{ py: 2, alignItems: 'center' }}>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={selectedstartDate}
                inputFormat="YYYY-MM-DD"
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectstartDate(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={selectendDate}
                inputFormat="YYYY-MM-DD"
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  setselectendDate(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
                minDate={selectedstartDate}
                onError={(newError) => setIsValidDate(newError)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleDateSort}
              disabled={selectendDate === null || selectedstartDate === null}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={() => handleClearDateSort(selectedstartDate, selectendDate)}
              disabled={selectendDate === null && selectedstartDate === null}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable options={options} columns={columns} data={tasks} />
                {/* <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleEditRedirect}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </Popover> */}
                {loading && (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                )}

                {!tasks && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
