import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Table,
  Stack,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  FormControl,
  Select,
  TextField,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'src/components/scrollbar';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import moment from 'moment';
import toast from 'react-hot-toast';

// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [showloading, setshowLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedstartDate, setselectstartDate] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [selectendDate, setselectendDate] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [emplydata, setEmplydata] = useState([]);
  const tomorrow = dayjs().add(1, 'day');
  const [totalEstimatedHours, settotalEstimatedHours] = useState(0);
  const [totaltask_hour, settotaltask_hour] = useState(0);
  const [isValidDate, setIsValidDate] = useState(false);
  const [employeeId, setEmployeeId] = useState();
  const [employeeIdValidation, setEmployeeIdValidation] = useState();
  const [searchEnable, setSearchEnable] = useState();

  const userdata = async (query) => {
    try {
      const response = await authAxios.get(`${PRIVATE_ROUTE?.GET_ADMIN}`);
      const filterEmployRole = response?.data?.data;
      // const emplydata = filterEmployRole?.map(({ _id, first_name, last_name, role }) => ({
      //   id: _id,
      //   label: `${first_name} ${last_name}`,
      // }));
      setEmplydata(filterEmployRole);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  useEffect(() => {
    userdata('');
  }, []);

  const columns = [
    {
      name: 'user_firstname',
      label: 'Employee Name',
      options: {
        sort: false,
      },
    },
    {
      name: 'job_number',
      label: 'Job Number',
      options: {
        sort: false,
      },
    },
    {
      name: 'task_name',
      label: 'Task name',
      options: {
        sort: false,
      },
    },
    {
      name: 'dwr_number',
      label: 'Dwr',
      options: {
        sort: false,
      },
    },
    {
      name: 'dwr_date',
      label: 'Dwr Date',
      options: {
        sort: false,
      },
    },
    {
      name: 'cost_Item',
      label: 'Cost Item',
      options: {
        sort: false,
      },
    },
    {
      name: 'task_hour',
      label: 'Dwr Hours',
      options: {
        sort: false,
      },
    },
    {
      name: 'accuracy',
      label: 'Accuracy',
      options: {
        sort: false,
      },
    },
  ];
  function handleChangeEmployee(e) {
    setEmployeeId(e.target.value);
    setEmployeeIdValidation();
  }
  const handlestartdatechenge = (date) => {
    setselectstartDate(date);
  };
  const handleEndDateChange = (date) => {
    // if (date.getTime() >= selectedstartDate.getTime()) {
    setselectendDate(date);
    // }
  };

  const options = {
    selectToolbarPlacement: 'none',

    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    filter: false,
    print: true,
    fixedHeader: true,
    count: count,
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data, _) => {
      const formattedData = data.map((item) => {
        if (typeof item === 'string') {
          // If the item is a string, return it directly
          return item;
        }
        if (typeof item === 'object' && Array.isArray(item.data)) {
          const result = [];

          // Loop through each element in item.data
          item.data.forEach((el) => {
            // If the element is a string, push it directly to the result array
            if (typeof el !== 'object') {
              result.push(el);
            }

            // If it's an object, handle extracting specific values
            if (typeof el === 'object') {
              // Check for the job view (for "001004")
              if (el.props?.to) {
                result.push(el.props.children.props.children);
              }

              // Check for manager and location info
              if (Array.isArray(el.props?.children)) {
                const childrenArray = el.props.children;

                const combinedString = `${childrenArray[1]}, ${childrenArray[5]}, ${childrenArray[9]}, ${childrenArray[13]}`;
                result.push(combinedString);

                // result.push(childrenArray[1], childrenArray[5], childrenArray[9], childrenArray[13]); // Push "manager 232" and "New York"
              }
            }
          });

          return result;
        }

        // In case item is neither a string nor an object in the expected format
        return null;
      });
      const result = formattedData.map((data, index) => ({
        index: index,
        data: data,
      }));
      const footerData = ['', '', '', 'Total DWR Hours :', `${totaltask_hour}`, ''];

      return `${buildHead(columns)}${buildBody(result)}\n${footerData.join(',')}\n`;
    },
    customFooter: () => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginBottom: '10px',
            marginTop: '15px',
            marginRight: '70px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Total DWR Hours : {totaltask_hour}
          </Typography>
        </div>
      );
    },
    customToolbar: () => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '10px' }}>
          <Typography variant="h6" gutterBottom>
            Total Accuracy : {totalEstimatedHours && totalAccuracy + '%'}
          </Typography>
        </div>
      );
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState.page);
      }
    },
    onSearchChange: (searchQuery) => {
      setSearchEnable(searchQuery);
      if (searchQuery !== null && searchQuery.length > 2) {
        handleSubmit({ searchQuery });
      }
      if (searchQuery === null) {
        handleSubmit('');
      }
    },
  };

  function roundToTwo(num) {
    return +(Math.round(num * 100 + 'e+2') + 'e-2');
  }

  const handleSubmit = async (queary) => {
    settotalEstimatedHours(0);
    settotaltask_hour(0);
    const postData = {
      employee_id: employeeId,
      start_date: selectedstartDate,
      end_date: selectendDate,
      per_page: rowsPerPage,
    };
    if (queary?.searchQuery) {
      postData.searchQuery = queary?.searchQuery;
    }
    if (employeeId === 'allEmployees') {
      postData.sortOrder = 'asc';
    }
    if (rowsPerPage * page < count) {
      postData.page = page + 1;
    }

    setLoading(true);
    if (employeeId) {
      try {
        if (employeeId) {
          const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_DWR_REPORT, postData);
          if (response.status === 200) {
            toast.success(response.data.message);
            setInvoices(
              response?.data?.data?.map((item, i, array) => {
                // let estimate_hour = item.taskdata.billing_line_items.labour_item.labour_cost_items;
                // const result = estimate_hour.find(
                //   (items) => items?.costItem === item?.billing_line_items?.labourCosts[0]?.costitem
                // );
                // let ext_hours = result?.estimated_hour ? result?.estimated_hour : 0;
                // let accuracy = ext_hours / item.task_hour;
                // accuracy = roundToTwo(accuracy);
                // settotalEstimatedHours((prevTotal) => prevTotal + parseFloat(ext_hours));
                settotaltask_hour((prevTotal) => prevTotal + parseFloat(item.task_hour));
                const estimate_hours = item.billing_line_items?.labourCosts[0]?.estimate_hour ?? 0;
                let accuracy = estimate_hours / item.task_hour;
                accuracy = roundToTwo(accuracy);
                settotalEstimatedHours((prevTotal) => prevTotal + (estimate_hours ? parseFloat(estimate_hours) : 0));

                return {
                  task_id: item.accuracy,
                  job_number: item.job_number,
                  task_name: (
                    <Link to={`/task/view/${item.task_id}`}>
                      <b>{item?.taskdata?.number_str}</b>
                    </Link>
                  ),
                  dwr_number: (
                    <Link to={`/dwr/view/${item._id}`}>
                      <b>{item?.dwr_number}</b>
                    </Link>
                  ),
                  dwr_date: new Date(item?.task_date).toISOString().split('T')[0],
                  cost_Item: item?.billing_line_items?.labourCosts[0]?.costitem, // (result?.costItem),
                  task_hour: item.task_hour === null ? 0 : `${item.task_hour}`,
                  accuracy: accuracy + '%',
                  user_firstname: `${item.user_firstname} ${item.user_lastname}`,
                };
              })
            );
            setCount(response?.data?.total);
          }
        }
      } catch (error) {
        console.error('Error submitting data:', error);
        toast.error('An error occurred while submitting data.');
        setLoading(false);
      }
    } else {
      toast.error('Employee is required');
      setEmployeeIdValidation('Employee is required');
    }
    setLoading(false);
  };
  useEffect(() => {
    if (employeeId) {
      handleSubmit();
    }
  }, [rowsPerPage, page]);
  const totalAccuracy = roundToTwo(totalEstimatedHours / totaltask_hour);

  return (
    <>
      <Grid sx={{ maxWidth: 1500, margin: 'auto' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
        </Stack>
        <Grid container xs={8} spacing={2} sx={{ py: 2, alignItems: 'top' }}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
              <Select
                label="Select Employee"
                fullWidth
                value={employeeId}
                onChange={handleChangeEmployee}
                error={employeeIdValidation}
              >
                <MenuItem value="allEmployees">All</MenuItem>
                {emplydata.map((item) => {
                  return <MenuItem value={item._id}>{`${item.first_name}  ${item.last_name}`}</MenuItem>;
                })}
              </Select>
              <FormHelperText sx={{ color: 'red' }}>
                {employeeIdValidation ? 'Employee is required' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <FormLabel> Start Date</FormLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                label="Start Date"
                value={selectedstartDate}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  handlestartdatechenge(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
                // minDate={selectedstartDate}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <FormLabel> End Date</FormLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="YYYY-MM-DD"
                label="End Date"
                value={selectendDate}
                onChange={(value) => {
                  const formattedDate = dayjs(value).format('YYYY-MM-DD');
                  handleEndDateChange(formattedDate);
                }}
                renderInput={(props) => <TextField {...props} />}
                minDate={selectedstartDate}
                onError={(newError) => setIsValidDate(newError)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button size="large" type="submit" variant="contained" onClick={handleSubmit} disabled={isValidDate}>
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable options={options} columns={columns} data={invoices} />
                {showloading ? (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showloading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                ) : (
                  ''
                )}
                {loading && (
                  <>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </>
                )}
                {!invoices && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
