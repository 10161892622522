import {
  TextField,
  Autocomplete,
  Chip,
  Select,
  Box,
  MenuItem,
  FormControl,
  FormHelperText,
  Grid,
  FormLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
  },
});
const ClientaddressDropdown = ({
  index,
  data,
  state,
  city,
  setValue,
  getValues,
  register,
  setclientlocation,
  categoryvalue,
  validation,
  secondstate,
  secondcity,
  errors,
  checked,
  setCondition,
  params,
  client,
}) => {
  const [personName, setPersonName] = React.useState('');
  const [stateValues, setStateValues] = useState(['', '', '']);
  const [cityValues, setCityValues] = useState([]);

  useEffect(() => {
    if (data && data.length > 0 && !params && index === 0) {
      const result = data.find((item) => item._id === '659f9792cc07d74fc15ba7fe');
      handleChangeField(0, `${result.name},${result._id}`);
    } else {
      const cityarray = data.find((obj) => obj.name === getValues(state));
      const optioncity = cityarray?.city;
      setCityValues(optioncity);
    }
  }, [data]);

  const handleChangeField = (stateindex, value) => {
    const [selectedName, selectedId] = value.split(',');
    setStateValues(data);
    setValue(state, selectedName);

    secondstate && setValue(secondstate, selectedId.trim());
    secondstate && setPersonName(getValues(state));

    const cityarray = data.find((obj) => obj.name === selectedName);
    const optioncity = cityarray?.city;
    setValue(city, optioncity[0]?.name);

    secondcity && setValue(secondcity, optioncity[0]?._id);
    secondcity && setPersonName(getValues(city));
    setCityValues(optioncity);
  };

  const handleCityChange = (cityIndex, value) => {
    const [selectedName, selectedId] = value.split(',');

    setValue(city, selectedName);

    secondcity && setValue(secondcity, selectedId.trim());
    secondcity && setPersonName(getValues(city));
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  // && errors[`${stateData[0]}`][`${stateData[1]}`] && errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]

  const stateData = state.split('.');
  let errorMassage = false;
  if (!checked) {
    if (
      errors !== null &&
      errors[`${stateData[0]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]
    ) {
      errorMassage = true;
    }
  } else if (errors !== null && errors[`${stateData[0]}`]) {
    errorMassage = true;
  }
  const getDisabled = (val) => {
    if (params) return { disabled: true };
    return {};
  };
  return (
    <>
      <Grid xs={12} md={6} item mt={2}>
        <FormLabel>
          State/Province<span style={{ color: 'red' }}>*</span>{' '}
        </FormLabel>
        <FormControl fullWidth>
          <Select
            fullWidth
            className={errorMassage ? 'error-input-border' : ''}
            value={getValues(state) || ''}
            defaultValue={getValues(state) || ''}
            onChange={(e) => {
              handleChangeField(index, e.target.value);
            }}
            // disabled={client && getValues('selectQuote') ? true : false}
            // {...getDisabled(true)}
            rules={{ required: true }}
            inputProps={
              <>
                {validation ? (
                  <>
                    <TextField
                      {...register(state, {
                        required: '**Field is required',
                      })}
                      error={Boolean(errorMassage)}
                      helperText={Boolean(errorMassage) ? '*Field is required' : null}
                    />
                  </>
                ) : (
                  <TextField
                    error={Boolean(errorMassage)}
                    helperText={Boolean(errorMassage) ? '*Field is required' : null}
                  />
                )}
              </>
            }
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                  <Chip label={selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                </Box>
              );
            }}
          >
            {data?.map((item) => {
              return (
                <MenuItem
                  key={item._id}
                  selected={personName === item.name}
                  value={`${item.name},${item._id} `}
                  style={getStyles(item.name, personName, theme)}
                >
                  {item.name === '' ? 'no data found' : item.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid xs={12} md={6} item mt={2}>
        <FormLabel>
          City<span style={{ color: 'red' }}>*</span>{' '}
        </FormLabel>
        <FormControl fullWidth>
          <Select
            fullWidth
            className={errorMassage ? 'error-input-border' : ''}
            value={getValues(city) || ''}
            defaultValue={getValues(city) || ''}
            onChange={(e) => {
              handleCityChange(index, e.target.value);
            }}
            // disabled={client && getValues('selectQuote') ? true : false}
            // {...getDisabled(true)}
            rules={{ required: true }}
            inputProps={
              <>
                {validation ? (
                  <>
                    <TextField
                      {...register(city, {
                        required: '**Field is required',
                      })}
                      error={Boolean(errorMassage)}
                      helperText={Boolean(errorMassage) ? '*Field is required' : null}
                    />
                  </>
                ) : (
                  <TextField
                    error={Boolean(errorMassage)}
                    helperText={Boolean(errorMassage) ? '*Field is required' : null}
                  />
                )}
              </>
            }
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                  <Chip label={selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                </Box>
              );
            }}
          >
            {cityValues?.map((item) => {
              return (
                <MenuItem
                  key={item._id}
                  selected={personName === item.name}
                  value={`${item.name},${item._id} `}
                  style={getStyles(item.name, personName, theme)}
                >
                  {item.name === '' ? 'no data found' : item.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
};

export default ClientaddressDropdown;
