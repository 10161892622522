import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import { isLoginStorage } from '../../utils/isAuthenticated';
import { useContext } from 'react';
import { AuthContext } from 'src/context/AuthContext';
import InitialChangePassword from 'src/sections/auth/login/InitialChangePassword';

function PrivateOutlate({ children }) {
  const {state} = useContext(AuthContext);
  return isLoginStorage() ?  state?.user?.password_changed === false? <InitialChangePassword/>  :children : <Navigate to="/login" />;
}
export default PrivateOutlate;

PrivateOutlate.propTypes = {
  children: PropTypes.any
};
