import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate, json } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import DailyWorkReportCreateForm from './DailyWorkReportCreateForm';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Extend dayjs with timezone and UTC plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const { PRIVATE_ROUTE } = API_ROUTES;

function DailyWorkReportForm() {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [taskdata, settaskData] = React.useState([]);
  const [costItemData, setcostItemData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, setstate] = useState();
  const [attachments, setAttachments] = React.useState('');
  const [signOne, setSignOne] = useState('');
  const [signTwo, setSignTwo] = useState('');
  const [mySignature, setMySignature] = useState('');
  const [jobIdValue, setJobIdValue] = useState();
  const [joballData, setjoballData] = useState([]);
  const [matchIndexes, setMatchIndexes] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    watch,
    control,
  } = useForm(
    !id
      ? {
          defaultValues: {
            task_name: '',
            // description: "",
            task_hour: 0,
            representative_sign: '',
            client_representative_sign: '',
            task_date: dayjs().format('YYYY-MM-DD'),
            client_approved_DWR: '',
            remark: '',
            submit_status: true,
          },
        }
      : {
          defaultValues: {
            task_name: '',
            // description: "",
            task_hour: 0,
            representative_sign: '',
            client_representative_sign: '',
            task_date: dayjs().format('YYYY-MM-DD'),
            client_representative: '',
            client_approved_DWR: '',
            remark: '',
            submit_status: true,
          },
        }
  );

  const getDWRFormById = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_DWR_BY_ID}/${id}`);

    if (data) {
      setstate(data?.data[0]);
      setJobIdValue(data?.data[0]?.taskdata?.job_id);
      setValue('task_name', data?.data[0]?.taskdata?.number_str);
      setValue('task_id', data?.data[0]?.task_id);
      setValue('job_id', data?.data[0]?.job_id);
      setValue('job', data?.data[0]?.taskdata?.job_id);
      setValue('project_manager_id', data?.data[0]?.task_id);
      setValue('project_managers_array', data?.data[0]?.project_manager);

      setValue('client_representative', data?.data[0]?.client_representative);
      setValue('client_representative_id ', data?.data[0]?.client_representative);
      // setValue('description', data?.data[0]?.description);
      // setValue('task_date', data?.data[0]?.task_date);
      setValue('task_date', dayjs(data?.data[0]?.task_date));

      setValue('task_hour', data?.data[0]?.task_hour);
      setValue('representative_sign', data?.data[0]?.representative_sign);
      setValue('client_representative_sign', data?.data[0]?.client_representative_sign);
      setValue('client_approved_DWR', data?.data[0]?.client_approved_DWR);
      setValue('submit_status', data?.data[0]?.submit_status);
      setValue('remark', data?.data[0]?.remark);
      setAttachments(data?.data[0]?.client_approved_DWR);
      setMySignature('');
      setSignOne(data?.data[0]?.representative_sign);
      setSignTwo(data?.data[0]?.client_representative_sign);
      data?.data[0]?.billing_line_items?.labourCosts?.map((element, i) => {
        setValue(`labourCosts.${i}.costitem`, element?.costitem);
        setValue(`labourCosts.${i}.employee`, element?.employee);
        setValue(`labourCosts.${i}.employee_name`, element?.employee_name);

        setValue(`labourCosts.${i}.start_time`, element?.start_time);
        setValue(`labourCosts.${i}.end_time`, element?.end_time);
        setValue(`labourCosts.${i}.hours`, element?.hours);
        setValue(`labourCosts.${i}.description`, element?.description);
      });
      data?.data[0]?.billing_line_items?.equipment?.map((element, i) => {
        setValue(`equipment.${i}.costitem`, element?.costitem);
        setValue(`equipment.${i}.name`, element?.name);
        setValue(`equipment.${i}.unit`, element?.unit);
        setValue(`equipment.${i}.quantity`, element?.quantity);
        setValue(`equipment.${i}.discription`, element?.discription);
        setValue(`equipment.${i}.include_discription`, element?.include_discription);
      });
    }
  }, [authAxios, id, setValue]);

  const gettaskdata = useCallback(async () => {
    // const { data } = await authAxios.get(
    //   `${PRIVATE_ROUTE.GET_TASKALL}`
    // );
    const costItemdata = await authAxios.get(`${PRIVATE_ROUTE.GET_COSTITEAMALL}`);
    const categorydata = await authAxios.get(`${PRIVATE_ROUTE.GET_CATEGORY}`);
    setcostItemData(costItemdata?.data?.data);
    setcategoryData(categorydata?.data?.data);
    if (id) {
      getDWRFormById();
    }
    setLoading(false);
  }, [authAxios]);

  const getclientdata = useCallback(async () => {
    const jobdata = await authAxios.get(`${PRIVATE_ROUTE.GET_JOBALL}`);
    setjoballData(jobdata.data.data);
  }, [authAxios, id]);

  useEffect(() => {
    getclientdata();
  }, []);

  useEffect(() => {
    gettaskdata();
  }, [gettaskdata, id]);

  function findLabourCostDuplicates(newdata) {
    const indexes = [];
    const seen = new Set(); // To track the first occurrence of duplicates

    newdata?.labourCosts.forEach((item, index) => {
      // Check if the pair (employee, costitem) already exists in 'seen'
      const key = `${item.employee}-${item.costitem}`;
      if (seen.has(key)) {
        // If it's a duplicate, add the current index to the 'indexes' array
        indexes.push(index);
      } else {
        // Otherwise, mark it as seen and keep the first occurrence
        seen.add(key);
      }
    });
    // Update the state with unique indexes (duplicates found)
    setMatchIndexes(indexes);
    const labourCostDuplicate = indexes.length > 0;
    return labourCostDuplicate;
  }

  const onSubmit = async (data) => {
    const user_data = JSON.parse(localStorage.getItem('LAND-FRONTED'));
    const newdata = {
      ...data,
      task_id: data?.task_id?.trim(),
      job_id: data?.job_id?.trim(),
      // user_id: user_data.user._id,
    };

    // const hasDuplicates = newdata?.labourCosts.some((item, index) => {
    //   return newdata?.labourCosts.some(
    //     (otherItem, otherIndex) =>
    //       index !== otherIndex && item.employee === otherItem.employee && item.costitem === otherItem.costitem
    //   );
    // });

    const hasDuplicates = findLabourCostDuplicates(newdata);
    if (hasDuplicates) {
      toast.error('Duplicate employee and costitem combination found. Please resolve the conflict.', {
        autoClose: 5000, // 5 seconds
      });
      return; // Exit the function if duplicates are found
    }
    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_DWR}${id}`, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/dwr/list');
      } else {
        toast.error(response.data.massage);
      }
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_DWR, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/dwr/list');
      } else {
        toast.error(response.data.massage);
      }
    }

    // setValue('task_name', '');
    // setValue('job', '');

    // // setValue('description', "");
    // setValue('task_date', '');
    // setValue('task_hour', '');
    // setValue('representative_sign', '');
    // setValue('client_representative_sign', '');
    // setValue('client_approved_DWR', '');
    // setValue('submit_status', '');
    // setValue('client_representative', '');
    // setValue('project_manager_id', '');
    // setValue('remark', '');
    // state?.billing_line_items?.labourCosts?.map((element, i) => {
    //   setValue(`labourCosts.${i}.costitem`, '');
    //   setValue(`labourCosts.${i}.employee`, '');
    //   setValue(`labourCosts.${i}.start_time`, '');
    //   setValue(`labourCosts.${i}.end_time`, '');
    //   setValue(`labourCosts.${i}.hours`, '');
    //   setValue(`labourCosts.${i}.description`, '');
    // });
    // state?.billing_line_items?.equipment?.map((element, i) => {
    //   setValue(`equipment.${i}.costitem`, '');
    //   setValue(`equipment.${i}.name`, '');
    //   setValue(`equipment.${i}.unit`, '');
    //   setValue(`equipment.${i}.quanitity`, '');
    //   setValue(`equipment.${i}.discription`, '');
    //   setValue(`equipment.${i}.include_discription`, '');
    // });
  };
  return (
    <>
      {loading ? (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        ''
      )}

      <DailyWorkReportCreateForm
        getValues={getValues}
        register={register}
        watch={watch}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
        setValue={setValue}
        taskdata={taskdata}
        costItemData={costItemData}
        categoryData={categoryData}
        editdata={state}
        attachments={attachments}
        setAttachments={setAttachments}
        signOne={signOne}
        setSignOne={setSignOne}
        signTwo={signTwo}
        setSignTwo={setSignTwo}
        mySignature={mySignature}
        setMySignature={setMySignature}
        authAxios={authAxios}
        PRIVATE_ROUTE={PRIVATE_ROUTE}
        joballData={joballData}
        jobIdValue={jobIdValue}
        setJobIdValue={setJobIdValue}
        setLoading={setLoading}
        matchIndexes={matchIndexes}
      />
    </>
  );
}

export default DailyWorkReportForm;
