import React, { useCallback, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Container,
  Card,
  Tabs,
  Tab,
  Grid,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pdf from 'react-to-pdf';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import moment from 'moment';

const { PRIVATE_ROUTE } = API_ROUTES;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function createData(_id, name, date, manager, status) {
  return { _id, name, date, manager, status };
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StaffDwrView = () => {
  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const ref = React.createRef();
  const [valueOne, setValueOne] = React.useState(0);
  const [valueTwo, setValueTwo] = React.useState(0);
  const [dwrData, setdwrData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [viewimage, setViewimage] = React.useState(false);
  const [clientView, setClientView] = React.useState(false);
  const [signView, setSignView] = React.useState(false);

  const handleChangeone = (event, newValue) => {
    setValueOne(newValue);
  };
  const handleChangetwo = (event, newValue) => {
    setValueTwo(newValue);
  };
  const handleEdit = () => {
    navigate(`/staff/dwr/update/${dwrData._id}`);
  };
  const handleDelete = async () => {
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_DWR}${dwrData._id}`);
    navigate('/staff/dwr/list');
  };

  const staffdwrdata = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_DWR_BY_ID}/${id}`);
    setdwrData(data?.data[0]);
    setLoading(false);
  }, [authAxios, id]);
  useEffect(() => {
    staffdwrdata();
  }, []);

  const renderActionButton = () => {
    let actionButton = '';
    if (!dwrData?.taskData?.is_completed || dwrData?.taskData?.is_completed !== 1) {
      actionButton = (
        <div>
          <Button
            sx={{ width: '10vw', marginRight: '20px' }}
            variant="contained"
            onClick={handleEdit}
            color="primary"
            startIcon={<EditIcon />}
            disabled={dwrData.status}
          >
            Edit
          </Button>
          <Button
            sx={{ width: '10vw', marginRight: '20px' }}
            onClick={handleDelete}
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            disabled={dwrData.status}
          >
            Delete
          </Button>
        </div>
      );
    }
    return actionButton;
  };

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2">
          DWR View
        </Typography>
        <Box sx={{ marginTop: '20px' }}>{renderActionButton()}</Box>
        <Box marginTop={'30px'}>
          <Grid container>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                DWR No :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.dwr_number}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task Number :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.taskdata?.number_str}
              </Typography>
            </Grid>
            {dwrData?.taskdata?.client_address && (
              <>
                <Grid sx={{ marginTop: '10px' }} xs={2} item>
                  <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                    Client Address :
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: '10px' }} xs={4} item>
                  <Typography variant="body1" color="initial">
                    {dwrData?.taskdata?.client_address}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Project Manager :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
              {dwrData?.project_manager_detail?.first_name + ' ' + dwrData?.project_manager_detail?.last_name}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Job Number :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.job_number}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Submit date :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {/* {dwrData?.submit_date} */}
                {dwrData?.submit_date && new Date(dwrData?.submit_date)?.toISOString().split('T')[0] }

              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Remark :
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.remark}
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task hour:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.task_hour}
              </Typography>
            </Grid>

            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Task Date:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {/* {moment(dwrData?.task_date).format('DD/MM/YYYY')} */}
                {dwrData?.task_date && new Date(dwrData?.task_date)?.toISOString().split('T')[0]}

              </Typography>
            </Grid>
            {dwrData?.client_representative && (
              <>
                <Grid sx={{ marginTop: '10px' }} xs={2} item>
                  <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                    Client Representative:
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: '10px' }} xs={4} item>
                  <Typography variant="body1" color="initial">
                    {dwrData?.client_representative}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid sx={{ marginTop: '10px' }} xs={2} item>
              <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                Submit Status:
              </Typography>
            </Grid>
            <Grid sx={{ marginTop: '10px' }} xs={4} item>
              <Typography variant="body1" color="initial">
                {dwrData?.submit_status === true ? (
                  <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt={'30px'} variant="h6" color="initial">
            Billing Line Details
          </Typography>
          <Card style={{ marginTop: '30px' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valueOne} onChange={handleChangeone} aria-label="basic tabs example">
                  <Tab label="Labour Costs" {...a11yProps(0)} />
                  <Tab label="Equipment and Material" {...a11yProps(1)} />
                  {/* <Tab label="Additional Fields" {...a11yProps(4)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={valueOne} index={0}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Labours
                </Typography>
                {dwrData?.billing_line_items?.labourCosts?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost item:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.costitem}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Description :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.description}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Employee :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {dwrData?.user_firstname + ' ' + dwrData?.user_lastname}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Hours:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.hours}
                        </Typography>
                      </Grid>
                      {/* <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Start Time:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.start_time}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          End Time:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.end_time}
                        </Typography>
                      </Grid> */}
                    </Grid>
                    {dwrData?.billing_line_items?.labourCosts?.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={valueOne} index={1}>
                <Typography mb={'5px'} variant="h6" color="initial">
                  Equipment
                </Typography>
                {dwrData?.billing_line_items?.equipment?.map((item) => (
                  <>
                    <Grid sx={{ marginLeft: 6 }} container>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Name :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.name}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Cost Item :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }}>{item?.costitem}</a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Description :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          {item?.discription}
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Quantity :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }}>{item?.quantity}</a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          Unit :
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }}>{item?.unit}</a>
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={2} item>
                        <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                          include Description:
                        </Typography>
                      </Grid>
                      <Grid sx={{ marginTop: '10px' }} xs={4} item>
                        <Typography variant="body1" color="initial">
                          <a style={{ color: 'black', textDecoration: 'none' }}>
                            {item?.include_discription === true ? (
                              <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} />
                            ) : (
                              <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />
                            )}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                    {dwrData?.billing_line_items?.equipment?.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                  </>
                ))}
              </TabPanel>
            </Box>
          </Card>
          <Grid direction={'row'} rowSpacing={2} container spacing={{ xs: 0, sm: 0, md: 5 }}>
            {/* <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Representative Sign
              </Typography>
              {dwrData?.representative_sign && (
                <>
                  <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                    <Grid md={3} item xs={6} mt={2}>
                      {signView === true ? (
                        ''
                      ) : (
                        <Button
                          variant="contained"
                          style={{ width: '150px' }}
                          component="label"
                          onClick={() => setSignView(true)}
                        >
                          view image
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    {signView === true ? (
                      <Grid mt={2}>
                        <img
                          src={`${process.env.REACT_APP_ASSET_URL}/dwr/signature/${dwrData?.representative_sign}`}
                          style={{ height: '150px', width: '150px' }}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Client Representative Sign
              </Typography>
              <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid md={3} item xs={6} mt={2}>
                  {clientView === true ? (
                    ''
                  ) : (
                    <Button
                      variant="contained"
                      style={{ width: '150px' }}
                      component="label"
                      onClick={() => setClientView(true)}
                    >
                      view image
                    </Button>
                  )}
                </Grid>
              </Grid>
              {clientView === true ? (
                <Grid mt={2}>
                  <img
                    src={`${process.env.REACT_APP_ASSET_URL}/dwr/signature/${dwrData?.client_representative_sign}`}
                    style={{ height: '150px', width: '150px' }}
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Client Approved DWR
              </Typography>
              <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid md={3} item xs={6} mt={2}>
                  {viewimage === true ? (
                    ''
                  ) : (
                    <Button
                      variant="contained"
                      style={{ width: '150px' }}
                      component="label"
                      onClick={() => setViewimage(true)}
                    >
                      view image
                    </Button>
                  )}
                </Grid>
              </Grid>
              {viewimage === true ? (
                <Grid mt={2}>
                  <img
                    src={`${process.env.REACT_APP_ASSET_URL}/dwr/attachments/${dwrData?.client_approved_DWR}`}
                    style={{ height: '150px', width: '150px' }}
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid> */}

            <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Representative Sign
              </Typography>
              {dwrData?.representative_sign ? (
                <>
                  <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                    <Grid md={3} item xs={6} mt={2}>
                      {signView === true ? (
                        ''
                      ) : (
                        <Button
                          variant="contained"
                          style={{ width: '150px' }}
                          component="label"
                          onClick={() => setSignView(true)}
                        >
                          view image
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    {signView === true ? (
                      <Grid mt={2}>
                        <img
                          src={`${process.env.REACT_APP_ASSET_URL}/dwr/signature/${dwrData?.representative_sign}`}
                          style={{ height: '150px', width: '150px' }}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </>
              ) : (
                <Grid md={3} item xs={6} mt={2}>
                  <Typography
                    my={'30px'}
                    variant="h6"
                    color="initial"
                    style={{ textAlign: 'center', marginLeft: '50px' }}
                  >
                    -
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Client Representative Sign
              </Typography>
              {dwrData?.client_representative_sign ? (
                <>
                  <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                    <Grid md={3} item xs={6} mt={2}>
                      {clientView === true ? (
                        ''
                      ) : (
                        <Button
                          variant="contained"
                          style={{ width: '150px' }}
                          component="label"
                          onClick={() => setClientView(true)}
                        >
                          view image
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {clientView === true ? (
                    <Grid mt={2}>
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/dwr/signature/${dwrData?.client_representative_sign}`}
                        style={{ height: '150px', width: '150px' }}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Grid md={3} item xs={6} mt={2}>
                  <Typography
                    my={'30px'}
                    variant="h6"
                    color="initial"
                    style={{ textAlign: 'center', marginLeft: '50px' }}
                  >
                    -
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography my={'30px'} variant="h6" color="initial">
                Client Approved DWR
              </Typography>
              {dwrData?.client_approved_DWR ? (
                <>
                  <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                    <Grid md={3} item xs={6} mt={2}>
                      {viewimage === true ? (
                        ''
                      ) : (
                        <Button
                          variant="contained"
                          style={{ width: '150px' }}
                          component="label"
                          onClick={() => setViewimage(true)}
                        >
                          view image
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {viewimage === true ? (
                    <Grid mt={2}>
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/dwr/attachments/${dwrData?.client_approved_DWR}`}
                        style={{ height: '150px', width: '150px' }}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Grid md={3} item xs={6} mt={2}>
                  <Typography
                    my={'30px'}
                    variant="h6"
                    color="initial"
                    style={{ textAlign: 'center', marginLeft: '50px' }}
                  >
                    -
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <div style={{ marginTop: '30px', display: 'flex' }}>
            {true ? (
              <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />
            )}
            <Typography mb={'5px'} variant="h6" color="initial">
              Active
            </Typography>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default StaffDwrView;
