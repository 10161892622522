import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Iconify from '../../../iconify';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function SeviceList() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);

  const navigate = useNavigate();
  const handleOpenMenu = (event,id) => {
    setOpen(event.currentTarget);
    setSelectedRow(id);
  };
  const handlejobcreateform = () => {
    navigate('/master/services/create');
  }  
  const handleEditRedirect = () => {
    setOpen(null);
    setSelectedRow(null);
    navigate(`/master/services/update/${selectedRow}`);
  }  
  const handleRowDelete = async() => {
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_SERVICE}${selectedRow}`);
    getUserdata()
    setOpen(null);
    setSelectedRow(null);
  }  
  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedRow(null);
  };
  

  const getUserdata = () =>{
    let isMounted = true;
    const getUsers = async () => {
      setLoading(true)
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_SERVICE}?page=${page + 1}&per_page=${rowsPerPage}`);
  
      if (isMounted) {
        setUsers(data?.data);
        setCount(data.total);
        setLoading(false);
      }
    };
  
    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
    });
    // getUsers()
    return () => {
      isMounted = false;
    };
  }
  useEffect(() => {
    getUserdata()
  }, [rowsPerPage, page]);

  const columns = [
    {
      name: "name",
      label: "Service Name",
      options: {
        draggable:true,
        sort:true,
        filter: true,
        display: true
      }
    },
    {
      name: "remark",
      label: "Remarks",
      options: {
        draggable:true,
        sort:true,
        filter: true,
        display: true,
      }
    },
    {
      name: "_id",
      label: "_id",
      options: {
        draggable:false,
        // sort:true,
        filter: false,
        display: false,
      }
    },
    {
      name: "",
      lable: "Action",
      options: {
        filter: true,
        // sort:true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton color="inherit" onClick={(e)=>handleOpenMenu(e,tableMeta.rowData[2])}>
              <Iconify width={15} icon={'eva:more-vertical-fill'} />
            </IconButton>
          );
        },
      },
    },
  ];


  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standard",
    filters: true,
    print: true,
    fixedHeader: true,
    count: count,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15)",
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    }
  };


  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Service
        </Typography>
        <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
          New Service
        </Button>
      </Stack>
      <MUIDataTable
        options={options}
        columns={columns}
        data={users}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditRedirect}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
