import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import download from 'downloadjs';
import moment from 'moment';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [showloading, setshowLoading] = useState(false);
  const [sortEnable, setSortEnable] = useState({});
  const [searchEnable, setSearchEnable] = useState();
  const navigate = useNavigate();

  const handleRowDelete = async (id) => {
    setshowLoading(true);
    await authAxios.get(`${PRIVATE_ROUTE?.GENERATE_TOKEN}/${id}`, { responseType: 'blob' }).then((response) => {
      const content = response.headers['content-type'];
      download(response.data, id + '.pdf', content);
      setshowLoading(false);
    });
    getInvoicedata('');
  };

  const handlecreateform = () => {
    navigate('/invoice/create');
  };

  const getInvoicedata = (query) => {
    let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    let search_query = query.searchQuery ? query.searchQuery : '';
    if (typeof query !== 'string') {
      sort_params = `&sortField=${query.sortField}&sortOrder=${query.sortOrder}&search=${search_query}`;
    } else {
      sort_params = `&search=${query}`;
    }
    let isMounted = true;
    const getInvoices = async () => {
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_INVOICE}?page=${page + 1}&per_page=${rowsPerPage}${sort_params}`
      );
      if (isMounted) {
        setInvoices(
          data?.data?.map((item) => {
            console.log("item-->", item);

            const task_name = item.task_details.map((taskdetails) => taskdetails.number_str);
            return {
              _id: item.qb_invoice_id,
              // generated_date: moment(item?.generated_date).format('DD/MM/YYYY'),
              generated_date: new Date(item?.generated_date).toISOString().split('T')[0],
              invoice_number: item?.number?.toString().padStart(6, '0'),
              client_number: (
                // <Link to={`/client/view/${item.client_id._id}`}>
                item.client_id.company_name
                // </Link>
              ),
              tasks:
                <Link to={`/task/view/${item.task_details[0]._id}`}>
                  <b>
                    {task_name.join(', ')}
                  </b>
                </Link>,
              // jobs:
              //   <Link to={`/job/view/${item.job_details._id}`}>
              //     <b>
              //       {item.job_details.number_str}
              //     </b>
              //   </Link>,
              invoice_amt: item.total_cost,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
      }
    };

    getInvoices().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    getInvoicedata('');
  }, [rowsPerPage, page]);

  const columns = [
    {
      name: '_id',
      label: 'Id',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton color="inherit" onClick={(e) => handleRowDelete(tableMeta.rowData[0])}>
                <Iconify width={15} icon={'eva:download-outline'} />
              </IconButton>
            </>
          );
        },
      },
    },
    {
      name: 'invoice_number',
      label: 'Invoice',
      options: {
        draggable: false,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'client_number',
      label: 'Client Name',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'tasks',
      label: 'Task Number',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    // {
    //   name: 'jobs',
    //   label: 'Job Number',
    //   options: {
    //     draggable: false,
    //     sort: false,
    //     filter: true,
    //     display: true,
    //   },
    // },
    {
      name: 'invoice_amt',
      label: 'Invoice amount',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'generated_date',
      label: 'Date Generated',
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    search: true,
    searchOpen: true,
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    filter: false,
    print: true,
    fixedHeader: true,
    count: count,
    selectableRows: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((item) => {
        console.log('item -->', item);
        if (typeof item === 'string') {
          // If the item is a string, return it directly
          return item;
        }
        if (typeof item === 'object' && Array.isArray(item.data)) {
          const result = [];

          // Loop through each element in item.data
          item.data.forEach((el) => {
            // If the element is a string, push it directly to the result array
            if (typeof el !== 'object') {
              result.push(el);
            }

            // If it's an object, handle extracting specific values
            if (typeof el === 'object') {
              // Check for the job view (for "001004")
              if (el.props?.to) {
                result.push(el.props.children.props.children);
              }

              // Check for manager and location info
              if (Array.isArray(el.props?.children)) {
                const childrenArray = el.props.children;

                const combinedString = `${childrenArray[1]}, ${childrenArray[5]}, ${childrenArray[9]}, ${childrenArray[13]}`;
                result.push(combinedString);

                // result.push(childrenArray[1], childrenArray[5], childrenArray[9], childrenArray[13]); // Push "manager 232" and "New York"
              }
            }
          });

          return result;
        }

        // In case item is neither a string nor an object in the expected format
        return null;
      });
      const result = formattedData.map((data, index) => ({
        index: index,
        data: data,
      }));

      return '\uFEFF' + buildHead(columns) + buildBody(result);
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onSearchChange: (searchQuery) => {
      setSearchEnable(searchQuery);
      if (searchQuery !== null && searchQuery.length > 2) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getInvoicedata({ sortField, sortOrder, searchQuery }); // Update your API call to include sorting
        } else {
          getInvoicedata(searchQuery);
        }
        setLoading(true);
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {
          const sortField = sortEnable.sortField; // Column name
          const sortOrder = sortEnable.sortOrder; // 'asc' or 'desc'
          getInvoicedata({ sortField, sortOrder }); // Update your API call to include sorting
        } else {
          getInvoicedata('');
        }
        setLoading(true);
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    // onTableChange: (action, tableState) => {
    //   if (action === 'changePage') {
    //     setPage(tableState.page);
    //   }
    // },
    onTableChange: (action, tableState) => {
      const { name, direction } = tableState.sortOrder;
      const sortField = name; // Column name
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable({ sortField, sortOrder });
        if (searchEnable) {
          getInvoicedata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getInvoicedata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
        setLoading(true);
      }
      setPage(tableState.page);
      if (action === 'changePage') {
        if (searchEnable) {
          getInvoicedata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getInvoicedata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
        setLoading(true);
      }
    },
  };

  return (
    <>
      <Grid sx={{ maxWidth: 1500, margin: 'auto' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Invoice
          </Typography>
          <Button variant="contained" onClick={handlecreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Invoice
          </Button>
        </Stack>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable options={options} columns={columns} data={invoices} />
                {showloading ? (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showloading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                ) : (
                  ''
                )}
                {loading && (
                  <div>
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                )}
                {!invoices && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
